import React, { useState, useEffect, useCallback } from "react";
import {
  Image,
  Platform,
  AppState,
  StyleSheet,
  SafeAreaView,
  Dimensions,
  Alert,
  View,
} from "react-native";
import { Card, Text } from "react-native-paper";
import { Button } from "../ui/";
import { materialTheme } from "../constants/";
import { Auth, Storage } from "aws-amplify";
import Amplify from "@aws-amplify/core";
import config from "../../aws-exports";
import { addTask } from "../api/tasks";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { updateAthenaApi, uploadAthenaDocument } from "../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import moment from "moment";
import * as DocumentPicker from "expo-document-picker";

Amplify.configure(config);
Storage.configure({
  AWSS3: {
    bucket: config.aws_user_files_s3_bucket, //Your bucket name;
    region: config.aws_user_files_s3_bucket_region, //Specify the region your bucket was created in;
    identityPoolId: config.aws_cognito_identity_pool_id,
  },
});
const { height, width } = Dimensions.get("window");

const isAndroid = Platform.OS === "android";
function uuidv4() {
  //https://stackoverflow.com/a/2117523/4047926
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const Signature = (props) => {
  const [patientId] = useState(props.patientId);
  const [departmentId] = useState(props.departmentId);
  const [practiceId] = useState(props.practiceId);
  const [name] = useState(props.name);
  const [patientName] = useState(props.patientName);

  const [image, setImage] = useState("");
  const [imageType, setImageType] = useState();
  const [appState, setAppState] = useState(AppState.currentState);

  const handleAppStateChangeAsync = (nextAppState) => {
    if (appState.match(/inactive|background/) && nextAppState === "active") {
      if (isAndroid && sketch) {
        setAppState(nextAppState);
        setId(uuidv4());
        setLines(sketch.lines);
        return;
      }
    }
    setAppState(nextAppState);
  };

  useEffect(() => {
    AppState.addEventListener("change", handleAppStateChangeAsync);
  }, []);

  useEffect(() => {
    function getFileExtension(base64Data) {
      const base = base64Data.split(";")[0];
      const extension = base.split("/").pop();
      setImageType(extension);
    }

    getFileExtension(image);
  }, [image]);

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "*",
    });
    setImage(result.uri);
  };

  const addDocument = async () => {
    let attachment_type = imageType.toUpperCase();
    let image_data = image.split(",")[1];
    let token = await AsyncStorage.getItem("Token");
    let url = `https://api.platform.athenahealth.com/v1/${practiceId}/patients/${patientId}/documents/admin`;
    await API.graphql(
      graphqlOperation(uploadAthenaDocument, {
        url,
        base64: image_data,
        documentType: attachment_type,
        departmentId: parseInt(departmentId),
        documentSubClass: name === "SignBillingInfo" ? "BILLING" : "CONSENT",
        token: token,
      })
    )
      .then((res) => {
        console.log("---------------->", res);
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };

  const addVerifiation = async () => {
    props.loading();
    let url = `https://api.platform.athenahealth.com/v1/${practiceId}/patients/${patientId}/privacyinformationverified?departmentid=${departmentId}`;
    if (name === "SignBillingInfo") {
      var apiBody = `departmentid=${departmentId}&patientsignature=true&signaturename=${patientName}&signaturedatetime=${moment().format(
        "MM/DD/YYYY HH:mm:ss"
      )}`;
    } else {
      var apiBody = `departmentid=${departmentId}&privacynotice=true&signaturename=${patientName}&signaturedatetime=${moment().format(
        "MM/DD/YYYY HH:mm:ss"
      )}`;
    }
    let token = await AsyncStorage.getItem("Token");
    await API.graphql(
      graphqlOperation(updateAthenaApi, {
        apiUrl: url,
        body: apiBody,
        token: token,
      })
    )
      .then((res) => {
        let response = res.data.updateAthenaApi.response;
        let json = JSON.parse(response);
        alert("status changed");
        console.log("athena privacy notice", json);
      })
      .then(() => {
        props.loading();
        props.navigation.goBack();
      })
      .catch((err) => {
        console.log("error while fetching Privacy Notice", err);
        props.loading();
      });
  };

  const onSing = async () => {
    if (image == "" || image == undefined || image == null) {
      Alert.alert("You must upload the image");
    } else {
      props.loading();
      Storage.put(`signature/${patientName + "." + imageType}`, image, {
        level: "public",
        contentType: image.substring(
          image.indexOf(":") + 1,
          image.indexOf(";")
        ),
      })
        .then(async (res) => {
          addVerifiation();
        })
        .catch((err) => {
          console.log(err);
        });

      addDocument();
    }
  };

  return (
    <SafeAreaView>
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Button
          disabled={image ? true : false}
          style={{
            marginVertical: 40,
            backgroundColor: image
              ? "green"
              : materialTheme.COLORS.BUTTON_COLOR,
          }}
          onPress={pickDocument}
        >
          Choose File
        </Button>
        {image && <Button onPress={onSing}>Upload</Button>}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  sketch: {
    flex: 1,
    backgroundColor: "white",
  },
  sketchContainer: {
    // height: "50%",
    borderTopWidth: 2,
    borderColor: "red",
    flex: 1,
  },
  image: {
    flex: 1,
  },
  imageContainer: {
    // height: "50%",
    flex: 0.5,
    borderTopWidth: 4,
    borderTopColor: "#E44262",
  },
  label: {
    width: "100%",
    padding: 5,
    alignItems: "center",
  },
  button: {
    // position: 'absolute',
    // bottom: 8,
    // left: 8,
    backgroundColor: materialTheme.COLORS.BUTTON_COLOR,
    paddingHorizontal: 22,
    marginRight: 15,
    width: "auto",
  },
});
export default Signature;
