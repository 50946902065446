import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Dimensions,
  Platform,
  Image,
  ActivityIndicator,
  SafeAreaView,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Card, Text, TextInput as Input, Surface } from "react-native-paper";
import { Button } from "../ui/";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Auth, Storage } from "aws-amplify";
import Amplify from "@aws-amplify/core";
import config from "../../aws-exports";

import { materialTheme } from "../constants/";
import { HeaderHeight } from "../constants/utils";

const { width } = Dimensions.get("window");
Amplify.configure(config);
const SignIn = (props) => {
  const { navigation } = props;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
    error: "",
  });
  const [active, setActive] = useState({
    email: false,
    password: false,
  });
  const [touched, setTouched] = useState({
    email: false,
    password: false,
  });

  const handleChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };
  const validate = () => {
    const errors = {};
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (user.email != "") {
      if (!emailRegex.test(user.email)) {
        errors.email = " Please enter valid Email";
      }
    } else {
      errors.email = " Please enter Email";
    }
    if (user.password == "") {
      errors.password = "Please enter Password";
    }
    return {
      errors,
      isValid: Object.keys(errors).length === 0,
    };
  };
  const toggleActive = (name) => {
    setActive({ [name]: true });
  };
  const onBlur = (name) => {
    setTouched({ ...touched, [name]: true });
  };
  const onSubmit = () => {
    setTouched({
      email: true,
      password: true,
    });
    if (isValid) {
      setLoading(true);
      Auth.signIn({
        username: user.email,
        password: user.password,
      })
        .then(async (user) => {
          console.log(user.attributes.sub, "SIGNIN");
          navigation.navigate("AppStack");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setUser({ ...user, error: err.message });
        });
    }
  };

  const onClear = () => {
    setUser({ email: "", password: "", error: "" });
    setActive({ email: false, password: false });
    setTouched({ email: false, password: false });
  };
  const { errors, isValid } = validate();
  return loading ? (
    <View style={{ flex: 1 }}>
      <ActivityIndicator
        size="large"
        animating={loading}
        color={materialTheme.COLORS.BRAND}
        style={{ margin: 10, flex: 1, alignSelf: "center" }}
      />
    </View>
  ) : (
    <KeyboardAwareScrollView
      behavior="padding"
      enabled
      style={{ flex: 1 }}
      contentContainerStyle={{ flexGrow: 1 }}
      keyboardShouldPersistTaps="handled"
    >
      <View style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            width: width >= 768 ? width * 0.5 : width,
            alignSelf: "center",
            paddingHorizontal: 15,
          }}
        >
          <Image
            source={require("../../assets/images/logo.png")}
            style={styles.logo}
          />
          <Text style={styles.textStyle}>Login</Text>
          <Text style={{ color: "red" }}>{user.error}</Text>
          <Input
            color={materialTheme.COLORS.INPUT_TEXT}
            placeholder="Email"
            type="email-address"
            autoCapitalize="none"
            bgColor="transparent"
            onBlur={() => onBlur("email")}
            onFocus={() => toggleActive("email")}
            theme={{
              colors: {
                text: materialTheme.COLORS.INPUT_TEXT,
                primary: materialTheme.COLORS.INPUT_TEXT,
              },
            }}
            underlineColor="transparent"
            placeholderTextColor={materialTheme.COLORS.MUTED}
            onChangeText={(text) => handleChange("email", text)}
            value={user.email}
            style={[styles.input, active.email ? styles.inputActive : null]}
          />
          {touched.email && errors.email && (
            <Text
              style={{
                color: "red",
                alignSelf: "flex-start",
                fontSize: 14,
              }}
            >
              {errors.email}
            </Text>
          )}
          <Input
            secureTextEntry={true}
            color={materialTheme.COLORS.INPUT_TEXT}
            iconColor={materialTheme.COLORS.PRIMARY}
            placeholderTextColor={materialTheme.COLORS.MUTED}
            placeholder="Password"
            bgColor="transparent"
            onBlur={() => onBlur("password")}
            onFocus={() => toggleActive("password")}
            theme={{
              colors: {
                text: materialTheme.COLORS.INPUT_TEXT,
                primary: materialTheme.COLORS.INPUT_TEXT,
              },
            }}
            underlineColor="transparent"
            onChangeText={(text) => handleChange("password", text)}
            value={user.password}
            style={[
              styles.input,
              { marginTop: 15 },
              active.password ? styles.inputActive : null,
            ]}
          />
          {touched.password && errors.password && (
            <Text
              style={{
                color: "red",
                alignSelf: "flex-start",
                fontSize: 14,
              }}
            >
              {errors.password}
            </Text>
          )}
          <Text
            onPress={() => {
              onClear();
              navigation.navigate("ChangePassword");
            }}
            style={{
              color: materialTheme.COLORS.CAPTION,
              fontSize: 13,
              alignSelf: "flex-end",
              lineHeight: 13 * 2,
            }}
          >
            Forgot your password?
          </Text>
          <Button
            color={materialTheme.COLORS.BUTTON_COLOR}
            style={{ height: 48, justifyContent: "center", marginTop: 20 }}
            onPress={() => onSubmit()}
          >
            SIGN IN
          </Button>
          <TouchableOpacity
            style={{ alignItems: "center" }}
            onPress={() => {
              onClear();
              navigation.navigate("Sign Up");
            }}
          >
            <Text
              style={{
                color: materialTheme.COLORS.CAPTION,
                alignSelf: "center",
                fontSize: 14,
                marginTop: 40,
              }}
            >
              Don't have an account? Sign Up
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <SafeAreaView style={{ backgroundColor: "white" }}>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderTopWidth: 0.5,
            width: width,
          }}
        >
          <Text style={styles.footer}>Meritore TeleCheck</Text>
        </View>
      </SafeAreaView>
    </KeyboardAwareScrollView>
  );
};
const styles = StyleSheet.create({
  signin: {
    marginTop: Platform.OS === "android" ? -HeaderHeight : 0,
  },
  input: {
    height: 50,
    borderRadius: 0,
    borderWidth: 0.25,
    backgroundColor: "transparent",
    borderColor: materialTheme.COLORS.DEFAULT,
  },
  inputActive: {
    borderBottomColor: materialTheme.COLORS.ACTIVE,
  },
  textStyle: {
    color: materialTheme.COLORS.PRIMARY,
    marginVertical: 20,
    fontSize: 24,
    fontWeight: "bold",
    alignSelf: "center",
  },
  logo: {
    marginBottom: 30,
    width: 300,
    height: 100,
    alignSelf: "center",
  },
  footer: {
    fontSize: 13,
    color: materialTheme.COLORS.CAPTION,
    fontWeight: "500",
    marginVertical: 5,
  },
});
export default SignIn;
