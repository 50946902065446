import React, { useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { Card, Text, Checkbox, Menu } from "react-native-paper";
import { materialTheme } from "../constants/";

import data from "../../data.json";

const { height, width } = Dimensions.get("window");
const PersonalInfoForm4 = (props) => {
  const { styles, errors } = props;
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  return (
    <View>
      <Text style={styles.label1}>Marital Status:</Text>
      <Menu
        visible={open1}
        onDismiss={() => setOpen1(false)}
        contentStyle={{ marginTop: 60 }}
        anchor={
          <TouchableOpacity
            style={[
              styles.input2,
              {
                backgroundColor: "transparent",
                justifyContent: "center",
                paddingLeft: 10,
              },
            ]}
            onPress={() => setOpen1(true)}
          >
            <Text
              style={{
                marginHorizontal: 5,
                color: materialTheme.COLORS.INPUT_TEXT,
              }}
            >
              {props.personalInfo.marritalStatus
                ? props.personalInfo.marritalStatus
                : "Please select..."}
            </Text>
          </TouchableOpacity>
        }
      >
        {[
          {
            name: "Divorced",
            value: "D",
          },
          {
            name: "Married",
            value: "M",
          },
          {
            name: "Single",
            value: "S",
          },
          {
            name: "Unknown",
            value: "U",
          },
          {
            name: "Widowed",
            value: "w",
          },
          {
            name: "Seperated",
            value: "S",
          },
          {
            name: "Partner",
            value: "P",
          },
        ].map((item) => {
          return (
            <Menu.Item
              key={item.name}
              onPress={() => {
                setOpen1(false);
                props.handleChange(item.value, "marritalStatus");
              }}
              title={item.name}
            />
          );
        })}
      </Menu>

      <Text style={styles.label1}>Language:</Text>
      <Menu
        visible={open2}
        onDismiss={() => setOpen2(false)}
        contentStyle={{ marginTop: 60 }}
        anchor={
          <TouchableOpacity
            style={[
              styles.input2,
              {
                backgroundColor: "transparent",
                justifyContent: "center",
                paddingLeft: 10,
              },
            ]}
            onPress={() => setOpen2(true)}
          >
            <Text
              style={{
                marginHorizontal: 5,
                color: materialTheme.COLORS.INPUT_TEXT,
              }}
            >
              {props.personalInfo.language
                ? props.personalInfo.language
                : "Please select..."}
            </Text>
          </TouchableOpacity>
        }
      >
        {data.language.map((item) => {
          return (
            <Menu.Item
              key={item.value}
              onPress={() => {
                setOpen2(false);
                props.handleChange(item.value, "language");
              }}
              title={item.name}
            />
          );
        })}
      </Menu>
      {errors.language && (
        <Text style={styles.errorText}>{errors.language}</Text>
      )}
      {/* <Text style={styles.label1}>Ethnicity:</Text>
      <Menu
        visible={open3}
        onDismiss={() => setOpen3(false)}
        anchor={
          <TouchableOpacity
            style={[
              styles.input2,
              {
                backgroundColor: "transparent",
                justifyContent: "center",
                paddingLeft: 10,
              },
            ]}
            onPress={() => setOpen3(true)}
          >
            <Text
              style={{
                marginHorizontal: 5,
                color: materialTheme.COLORS.INPUT_TEXT,
              }}
            >
              {props.personalInfo.ethnicity
                ? props.personalInfo.ethnicity
                : "Select Ethinicity"}
            </Text>
          </TouchableOpacity>
        }
      >
        {data.ethinicity.map((item) => {
          return (
            <Menu.Item
              key={item.value}
              onPress={() => {
                setOpen3(false);
                props.handleChange(item.value, "ethnicity");
              }}
              title={item.name}
            />
          );
        })}
      </Menu>
      {errors.ethinicity && (
        <Text style={styles.errorText}>{errors.ethinicity}</Text>
      )} */}
      <Text style={styles.label1}>Race:</Text>
      <Menu
        visible={open4}
        onDismiss={() => setOpen4(false)}
        anchor={
          <TouchableOpacity
            style={[
              styles.input2,
              {
                backgroundColor: "transparent",
                justifyContent: "center",
                paddingLeft: 10,
              },
            ]}
            onPress={() => setOpen4(true)}
          >
            <Text
              style={{
                marginHorizontal: 5,
                color: materialTheme.COLORS.INPUT_TEXT,
              }}
            >
              {props.personalInfo.race
                ? props.personalInfo.race
                : "Select Race"}
            </Text>
          </TouchableOpacity>
        }
      >
        {data.race.map((item) => {
          return (
            <Menu.Item
              key={item.value}
              onPress={() => {
                setOpen4(false);
                props.handleChange(item.value, "race");
              }}
              title={item.name}
            />
          );
        })}
      </Menu>
      {errors.race && <Text style={styles.errorText}>{errors.race}</Text>}
      <Text style={styles.label1}>Consent to Call:</Text>
      <Menu
        visible={open5}
        onDismiss={() => setOpen5(false)}
        anchor={
          <TouchableOpacity
            style={[
              styles.input2,
              {
                backgroundColor: "transparent",
                justifyContent: "center",
                paddingLeft: 10,
              },
            ]}
            onPress={() => setOpen5(true)}
          >
            <Text
              style={{
                marginHorizontal: 5,
                color: materialTheme.COLORS.INPUT_TEXT,
              }}
            >
              {props.personalInfo.consenToCall === true ? "True" : "False"}
            </Text>
          </TouchableOpacity>
        }
      >
        <Menu.Item
          onPress={() => {
            setOpen5(false);
            props.handleChange(true, "consenToCall");
          }}
          title="True"
        />
        <Menu.Item
          onPress={() => {
            setOpen5(false);
            props.handleChange(false, "consenToCall");
          }}
          title="False"
        />
      </Menu>
      {errors.consenToCall && (
        <Text style={styles.errorText}>{errors.consenToCall}</Text>
      )}
      <Text
        style={{
          marginHorizontal: 5,
          marginVertical: 15,
          color: materialTheme.COLORS.ERROR,
        }}
      >
        ** Please Update the changes before moving to next screen. **
      </Text>
    </View>
  );
};
export default PersonalInfoForm4;
