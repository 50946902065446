import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  ActivityIndicator,
  Alert,
} from "react-native";
import React, { useEffect, useState } from "react";
import * as Linking from "expo-linking";
import * as SplashScreen from "expo-splash-screen";
import { NavigationContainer } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Provider as PaperProvider, DefaultTheme } from "react-native-paper";
import { Amplify, Auth, API, graphqlOperation } from "aws-amplify";
import awsconfig from "./aws-exports";
import { getAccessToken } from "./src/graphql/queries";
import { materialTheme } from "./src/constants/";
import Screens from "./src/navigation/Screens";

Amplify.configure(awsconfig);
const prefix = Linking.createURL("/");
const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: "#1E3E72",
    accent: "#DCDCDC",
  },
};

const getAccess = async () => {
  await API.graphql(graphqlOperation(getAccessToken))
    .then(async (res) => {
      let token = res.data.getAccessToken.response;
      console.log(token, "#### TOKEN #####");
      await AsyncStorage.setItem("Token", token);
    })
    .catch((err) => {
      console.log("error while getting access token", err);
      alert("Something went wrong, please reload the app.");
    });
};

const onGetLinkIds = async () => {
  Linking.getInitialURL().then(async (url) => {
    if (url) {
      let { queryParams } = Linking.parse(url);
      let providerId = queryParams.providerId;
      let patientId = queryParams.patientId;
      if (providerId && patientId) {
        let obj = {
          fId: providerId,
          pId: patientId,
        };
        await AsyncStorage.setItem("EmrApptIds", JSON.stringify(obj));
      } else {
        await AsyncStorage.removeItem("EmrApptIds");
      }
    }
  });
};

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);
  const [loggedInStatus, setloggedInStatus] = useState(false);
  const [user, setUser] = useState();

  const linking = {
    prefixes: [prefix],
  };

  const isUserSignedIn = async () => {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user.attributes.sub);
        setloggedInStatus(true);
      })
      .catch((err) => {
        console.log(err);
        setloggedInStatus(false);
      });
  };

  useEffect(() => {
    async function prepare() {
      try {
        await onGetLinkIds();
        await getAccess();
        await isUserSignedIn();
      } catch (e) {
        console.warn(e);
      } finally {
        setAppIsReady(true);
      }
    }
    prepare();
  }, []);

  useEffect(() => {
    async function appLoaded() {
      if (setAppIsReady) {
        await SplashScreen.hideAsync();
      }
    }
    appLoaded();
  }, [setAppIsReady]);

  useEffect(() => {
    const timer = setInterval(() => {
      getAccess();
      console.log("Access Token Added !!");
    }, 1000 * 60 * 60);
    return () => clearTimeout(timer);
  }, []);

  if (!appIsReady) {
    return (
      <View style={styles.container}>
        <ActivityIndicator
          size="large"
          color={materialTheme.COLORS.BRAND}
          style={{ flex: 1, margin: 10, alignSelf: "center" }}
        />
      </View>
    );
  } else {
    return (
      <PaperProvider theme={theme}>
        <NavigationContainer
          linking={Platform.OS == "web" ? null : linking}
          independent={true}
        >
          {Platform.OS === "ios" && <StatusBar barStyle="default" />}
          <Screens loggedInStatus={loggedInStatus} user={user} />
        </NavigationContainer>
      </PaperProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
