import { API, graphqlOperation } from "aws-amplify";
import config from "../../aws-exports";
import {
  listUsers,
  getUser,
  getSignedUrl,
  getAwsUser,
} from "../graphql/queries";
import {
  createUser,
  deleteUnconfirmedUser,
  updateUser,
} from "../graphql/mutations";

API.configure(config);

export const addUser = async (user) => {
  return await API.graphql(
    graphqlOperation(createUser, {
      input: user,
    })
  );
};
export const upgradeUser = async (user) => {
  return await API.graphql(
    graphqlOperation(updateUser, {
      input: user,
    })
  );
};
export const fetchUser = async (id) => {
  return await API.graphql(
    graphqlOperation(getUser, {
      id: id,
    })
  );
};
export const fetchUserByAwsUserId = async (id) => {
  return await API.graphql(
    graphqlOperation(listUsers, {
      filter: { awsUserId: { eq: id } },
      limit: 1000,
    })
  );
};
export const fetchSignedUrl = async (fileName, folderName, level) => {
  return await API.graphql(
    graphqlOperation(getSignedUrl, { fileName, folderName, level })
  );
};
export const removeUnconfirmedUser = async (userName) => {
  return await API.graphql(
    graphqlOperation(deleteUnconfirmedUser, { userName })
  );
};
export const fetchAwsUser = async (userName) => {
  return await API.graphql(graphqlOperation(getAwsUser, { userName }));
};
