import React from "react";
import { StyleSheet } from "react-native";
import { Button, Text } from "react-native-paper";
import materialTheme from "../constants/Theme";

export default class MKButton extends React.Component {
  render() {
    const { children, style, ...props } = this.props;
    return (
      <Button mode="contained" style={[style]} {...props}>
        {children}
      </Button>
    );
  }
}

const styles = StyleSheet.create({
  gradient: {
    borderWidth: 0,
    borderRadius: 10 * 2,
  },
});
