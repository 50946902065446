import { API, graphqlOperation } from "aws-amplify";
import config from "../../aws-exports";
import { listTasks, getTask } from "../graphql/queries";
import { createTask, updateTask } from "../graphql/mutations";

API.configure(config);

export const addTask = async (task) => {
  return await API.graphql(
    graphqlOperation(createTask, {
      input: task,
    })
  );
};
export const upgradeTask = async (task) => {
  return await API.graphql(
    graphqlOperation(updateTask, {
      input: task,
    })
  );
};

export const fetchTask = async (pId, fId, aId, an) => {
  return await API.graphql(
    graphqlOperation(listTasks, {
      filter: {
        patientId: { eq: pId },
        facilityId: { eq: fId },
        activityName: { eq: an },
        appointmentId: { eq: aId },
      },
      limit: 1000,
    })
  );
};
export const fetchTaskByFIdAndPId = async (pId, fId, an) => {
  return await API.graphql(
    graphqlOperation(listTasks, {
      filter: {
        patientId: { eq: pId },
        facilityId: { eq: fId },
      },
      limit: 1000,
    })
  );
};
export const fetchTaskByFPAIds = async (pId, fId, aId) => {
  return await API.graphql(
    graphqlOperation(listTasks, {
      filter: {
        patientId: { eq: pId },
        facilityId: { eq: fId },
        appointmentId: { eq: aId },
      },
      limit: 1000,
    })
  );
};
