import React, { useEffect, useState } from "react";
import {
  Dimensions,
  ActivityIndicator,
  Image,
  Platform,
  Button,
  View,
} from "react-native";
import { Card, Text } from "react-native-paper";
import PDFView from "./PdfView";
import UploadSignature from "./UploadSignature";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { materialTheme } from "../constants";
import { fetchSignedUrl } from "../api/users";

const { width } = Dimensions.get("screen");

const SignPrivacyNotice = (props) => {
  let departmentId = props.route.params.departmentId;
  let patientId = props.route.params.patientId;
  let practiceId = props.route.params.practiceId;
  let patientName = props.route.params.patientName;
  let privacyNotice = props.route.params.privacyNotice;

  const [loading, setLoading] = useState(false);
  const [url] = useState(
    "https://meritcare-prod92018-production.s3.us-east-2.amazonaws.com/public/documents/PrivacySign.pdf"
  );

  return loading ? (
    <View style={{ flex: 1 }}>
      <ActivityIndicator
        size="large"
        animating={loading}
        color={materialTheme.COLORS.BRAND}
        style={{ margin: 10, flex: 1, alignSelf: "center" }}
      />
    </View>
  ) : privacyNotice ? (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Image
          source={require("../../assets/images/checked2.png")}
          style={{ height: 40, width: 40 }}
        />
        <Text
          style={{
            color: materialTheme.COLORS.CAPTION,
            fontSize: 25,
            textAlign: "center",
          }}
        >
          You have been completed task !
        </Text>
      </View>
    </View>
  ) : (
    <View style={{ flex: 7, backgroundColor: "transparent" }}>
      <View
        style={{
          flex: 0.65,
          elevation: 0,
          paddingHorizontal: 0,
        }}
      >
        <PDFView
          {...props}
          url={
            url != ""
              ? url
              : "https://meritcare-prod92018-production.s3.us-east-2.amazonaws.com/public/documents/PrivacySign.docx"
          }
        />
      </View>
      <Text
        style={{
          marginHorizontal: 15,
          marginVertical: 15,
          color: materialTheme.COLORS.ERROR,
          textAlign: "center",
        }}
      >
        ** Please print the form, sign and upload. **
      </Text>
      <View style={{ flex: 0.35, paddingHorizontal: 0, alignItems: "center" }}>
        <UploadSignature
          {...props}
          departmentId={departmentId}
          practiceId={practiceId}
          patientId={patientId}
          patientName={patientName}
          loading={() => setLoading(!loading)}
          name="SignPrivacyNotice"
        />
      </View>
    </View>
  );
};
export default SignPrivacyNotice;
