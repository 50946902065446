import * as React from "react";
import PDFReader from "rn-pdf-reader-js";
import { ActivityIndicator, Dimensions, Platform } from "react-native";
import { Card } from "react-native-paper";

const { width } = Dimensions.get("screen");

const PDFView = (props) => {
  const [loading, setLoading] = React.useState(true);
  if (Platform.OS === "web") {
    return (
      <iframe
        src={props.url}
        style={{
          width: width >= 768 ? width * 0.5 : width,
          height: "80vh",
          alignSelf: "center",
        }}
      />
    );
  } else
    return (
      <>
        <PDFReader
          onLoad={() => setLoading(true)}
          onLoadEnd={() => setLoading(false)}
          noLoader={false}
          source={{
            uri: props.url,
          }}
        />
        {loading && Platform.OS == "android" && (
          <ActivityIndicator
            size="large"
            color="#00dbad"
            style={{
              position: "absolute",
              flex: 1,
              alignSelf: "center",
              color: "red",
              top: 50,
            }}
          />
        )}
      </>
    );
};
export default PDFView;
