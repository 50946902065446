/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteUnconfirmedUser = /* GraphQL */ `
  mutation DeleteUnconfirmedUser($userName: String) {
    deleteUnconfirmedUser(userName: $userName) {
      status
      response
    }
  }
`;
export const sendAppointment = /* GraphQL */ `
  mutation SendAppointment(
    $userEmail: String
    $userPhone: String
    $message: String
    $token: String
  ) {
    sendAppointment(
      userEmail: $userEmail
      userPhone: $userPhone
      message: $message
      token: $token
    ) {
      status
      response
    }
  }
`;
export const updateAthenaApi = /* GraphQL */ `
  mutation UpdateAthenaApi($apiUrl: String, $body: String, $token: String) {
    updateAthenaApi(apiUrl: $apiUrl, body: $body, token: $token) {
      status
      response
    }
  }
`;
export const putAthenaApi = /* GraphQL */ `
  mutation PutAthenaApi($apiUrl: String, $body: String, $token: String) {
    putAthenaApi(apiUrl: $apiUrl, body: $body, token: $token) {
      status
      response
    }
  }
`;
export const deleteAthenaApi = /* GraphQL */ `
  mutation DeleteAthenaApi($apiUrl: String, $body: String, $token: String) {
    deleteAthenaApi(apiUrl: $apiUrl, body: $body, token: $token) {
      status
      response
    }
  }
`;
export const uploadAthenaDocument = /* GraphQL */ `
  mutation UploadAthenaDocument(
    $url: String
    $departmentId: Int
    $documentSubClass: String
    $base64: String
    $documentType: String
    $fileName: String
    $token: String
  ) {
    uploadAthenaDocument(
      url: $url
      departmentId: $departmentId
      documentSubClass: $documentSubClass
      base64: $base64
      documentType: $documentType
      fileName: $fileName
      token: $token
    ) {
      status
      response
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      awsUserId
      firstName
      lastName
      middleName
      email
      phone
      role
      active
      facilities
      personalInfo
      insuranceCardInfo
      idCardInfo
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      awsUserId
      firstName
      lastName
      middleName
      email
      phone
      role
      active
      facilities
      personalInfo
      insuranceCardInfo
      idCardInfo
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      awsUserId
      firstName
      lastName
      middleName
      email
      phone
      role
      active
      facilities
      personalInfo
      insuranceCardInfo
      idCardInfo
      createdAt
      updatedAt
    }
  }
`;
export const createFacility = /* GraphQL */ `
  mutation CreateFacility(
    $input: CreateFacilityInput!
    $condition: ModelFacilityConditionInput
  ) {
    createFacility(input: $input, condition: $condition) {
      id
      email
      phone
      facilityName
      facilityDescription
      facilityLogo
      street1
      street2
      city
      state
      zipcode
      website
      emr
      emrProviderId
      privacyNoticeUrl
      billingNoticeUrl
      staffUsers {
        id
        awsUserId
        firstName
        lastName
        middleName
        email
        phone
        role
        active
        facilities
        personalInfo
        insuranceCardInfo
        idCardInfo
        createdAt
        updatedAt
      }
      doctors
      createdAt
      updatedAt
    }
  }
`;
export const updateFacility = /* GraphQL */ `
  mutation UpdateFacility(
    $input: UpdateFacilityInput!
    $condition: ModelFacilityConditionInput
  ) {
    updateFacility(input: $input, condition: $condition) {
      id
      email
      phone
      facilityName
      facilityDescription
      facilityLogo
      street1
      street2
      city
      state
      zipcode
      website
      emr
      emrProviderId
      privacyNoticeUrl
      billingNoticeUrl
      staffUsers {
        id
        awsUserId
        firstName
        lastName
        middleName
        email
        phone
        role
        active
        facilities
        personalInfo
        insuranceCardInfo
        idCardInfo
        createdAt
        updatedAt
      }
      doctors
      createdAt
      updatedAt
    }
  }
`;
export const deleteFacility = /* GraphQL */ `
  mutation DeleteFacility(
    $input: DeleteFacilityInput!
    $condition: ModelFacilityConditionInput
  ) {
    deleteFacility(input: $input, condition: $condition) {
      id
      email
      phone
      facilityName
      facilityDescription
      facilityLogo
      street1
      street2
      city
      state
      zipcode
      website
      emr
      emrProviderId
      privacyNoticeUrl
      billingNoticeUrl
      staffUsers {
        id
        awsUserId
        firstName
        lastName
        middleName
        email
        phone
        role
        active
        facilities
        personalInfo
        insuranceCardInfo
        idCardInfo
        createdAt
        updatedAt
      }
      doctors
      createdAt
      updatedAt
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      userId
      facilityId
      appointmentDate
      purpose
      doctorName
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      userId
      facilityId
      appointmentDate
      purpose
      doctorName
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      userId
      facilityId
      appointmentDate
      purpose
      doctorName
      status
      createdAt
      updatedAt
    }
  }
`;
export const createEmrAppointment = /* GraphQL */ `
  mutation CreateEmrAppointment(
    $input: CreateEmrAppointmentInput!
    $condition: ModelEmrAppointmentConditionInput
  ) {
    createEmrAppointment(input: $input, condition: $condition) {
      id
      providerId
      patientId
      patientPhone
      patientEmail
      appointmentDate
      purpose
      doctorName
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateEmrAppointment = /* GraphQL */ `
  mutation UpdateEmrAppointment(
    $input: UpdateEmrAppointmentInput!
    $condition: ModelEmrAppointmentConditionInput
  ) {
    updateEmrAppointment(input: $input, condition: $condition) {
      id
      providerId
      patientId
      patientPhone
      patientEmail
      appointmentDate
      purpose
      doctorName
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmrAppointment = /* GraphQL */ `
  mutation DeleteEmrAppointment(
    $input: DeleteEmrAppointmentInput!
    $condition: ModelEmrAppointmentConditionInput
  ) {
    deleteEmrAppointment(input: $input, condition: $condition) {
      id
      providerId
      patientId
      patientPhone
      patientEmail
      appointmentDate
      purpose
      doctorName
      status
      createdAt
      updatedAt
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      patientId
      appointmentId
      facilityId
      activityType
      activityName
      activityDefinition
      dueDateTime
      activityData
      completed
      createdAt
      updatedAt
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      patientId
      appointmentId
      facilityId
      activityType
      activityName
      activityDefinition
      dueDateTime
      activityData
      completed
      createdAt
      updatedAt
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      patientId
      appointmentId
      facilityId
      activityType
      activityName
      activityDefinition
      dueDateTime
      activityData
      completed
      createdAt
      updatedAt
    }
  }
`;
export const createTaskCategory = /* GraphQL */ `
  mutation CreateTaskCategory(
    $input: CreateTaskCategoryInput!
    $condition: ModelTaskCategoryConditionInput
  ) {
    createTaskCategory(input: $input, condition: $condition) {
      id
      name
      componentName
      createdAt
      updatedAt
    }
  }
`;
export const updateTaskCategory = /* GraphQL */ `
  mutation UpdateTaskCategory(
    $input: UpdateTaskCategoryInput!
    $condition: ModelTaskCategoryConditionInput
  ) {
    updateTaskCategory(input: $input, condition: $condition) {
      id
      name
      componentName
      createdAt
      updatedAt
    }
  }
`;
export const deleteTaskCategory = /* GraphQL */ `
  mutation DeleteTaskCategory(
    $input: DeleteTaskCategoryInput!
    $condition: ModelTaskCategoryConditionInput
  ) {
    deleteTaskCategory(input: $input, condition: $condition) {
      id
      name
      componentName
      createdAt
      updatedAt
    }
  }
`;
