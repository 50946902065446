import React from 'react';
import { IconButton } from 'react-native-paper';


export default class IconExtra extends React.Component {
 
  render() {
    const { name, ...rest } = this.props;
    if (name ) {
      return <IconButton icon={name}  {...rest} />;
    }
    return null;
  }
}
