/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSignedUrl = /* GraphQL */ `
  query GetSignedUrl($fileName: String, $folderName: String, $level: String) {
    getSignedUrl(fileName: $fileName, folderName: $folderName, level: $level) {
      status
      response
    }
  }
`;
export const getAwsUser = /* GraphQL */ `
  query GetAwsUser($userName: String) {
    getAwsUser(userName: $userName) {
      status
      response
    }
  }
`;
export const getAthenaApi = /* GraphQL */ `
  query GetAthenaApi($apiUrl: String, $token: String) {
    getAthenaApi(apiUrl: $apiUrl, token: $token) {
      status
      response
    }
  }
`;
export const getAccessToken = /* GraphQL */ `
  query GetAccessToken {
    getAccessToken {
      status
      response
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      awsUserId
      firstName
      lastName
      middleName
      email
      phone
      role
      active
      facilities
      personalInfo
      insuranceCardInfo
      idCardInfo
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        awsUserId
        firstName
        lastName
        middleName
        email
        phone
        role
        active
        facilities
        personalInfo
        insuranceCardInfo
        idCardInfo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFacility = /* GraphQL */ `
  query GetFacility($id: ID!) {
    getFacility(id: $id) {
      id
      email
      phone
      facilityName
      facilityDescription
      facilityLogo
      street1
      street2
      city
      state
      zipcode
      website
      emr
      emrProviderId
      privacyNoticeUrl
      billingNoticeUrl
      staffUsers {
        id
        awsUserId
        firstName
        lastName
        middleName
        email
        phone
        role
        active
        facilities
        personalInfo
        insuranceCardInfo
        idCardInfo
        createdAt
        updatedAt
      }
      doctors
      createdAt
      updatedAt
    }
  }
`;
export const listFacilitys = /* GraphQL */ `
  query ListFacilitys(
    $filter: ModelFacilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacilitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone
        facilityName
        facilityDescription
        facilityLogo
        street1
        street2
        city
        state
        zipcode
        website
        emr
        emrProviderId
        privacyNoticeUrl
        billingNoticeUrl
        staffUsers {
          id
          awsUserId
          firstName
          lastName
          middleName
          email
          phone
          role
          active
          facilities
          personalInfo
          insuranceCardInfo
          idCardInfo
          createdAt
          updatedAt
        }
        doctors
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      userId
      facilityId
      appointmentDate
      purpose
      doctorName
      status
      createdAt
      updatedAt
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        facilityId
        appointmentDate
        purpose
        doctorName
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmrAppointment = /* GraphQL */ `
  query GetEmrAppointment($id: ID!) {
    getEmrAppointment(id: $id) {
      id
      providerId
      patientId
      patientPhone
      patientEmail
      appointmentDate
      purpose
      doctorName
      status
      createdAt
      updatedAt
    }
  }
`;
export const listEmrAppointments = /* GraphQL */ `
  query ListEmrAppointments(
    $filter: ModelEmrAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmrAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        providerId
        patientId
        patientPhone
        patientEmail
        appointmentDate
        purpose
        doctorName
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      patientId
      appointmentId
      facilityId
      activityType
      activityName
      activityDefinition
      dueDateTime
      activityData
      completed
      createdAt
      updatedAt
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        appointmentId
        facilityId
        activityType
        activityName
        activityDefinition
        dueDateTime
        activityData
        completed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTaskCategory = /* GraphQL */ `
  query GetTaskCategory($id: ID!) {
    getTaskCategory(id: $id) {
      id
      name
      componentName
      createdAt
      updatedAt
    }
  }
`;
export const listTaskCategorys = /* GraphQL */ `
  query ListTaskCategorys(
    $filter: ModelTaskCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        componentName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
