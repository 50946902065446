import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  Image,
  Platform,
  View,
} from "react-native";
import { Card, Text } from "react-native-paper";
import PDFView from "./PdfView";
import UploadSignature from "./UploadSignature";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { fetchTask } from "../api/tasks";
import { materialTheme } from "../constants";
import { fetchSignedUrl } from "../api/users";
import config from "../../aws-exports";
import { Auth } from "aws-amplify";

Auth.configure(config);

const { width } = Dimensions.get("screen");

const SignBillingInfo = (props) => {
  let departmentId = props.route.params.departmentId;
  let patientId = props.route.params.patientId;
  let practiceId = props.route.params.practiceId;
  let patientName = props.route.params.patientName;
  let billingNotice = props.route.params.billingNotice;

  const [url] = useState(
    "https://meritcare-prod92018-production.s3.us-east-2.amazonaws.com/public/documents/BillingSign.pdf"
  );
  const [loading, setLoading] = useState(false);

  return loading ? (
    <View style={{ flex: 1 }}>
      <ActivityIndicator
        size="large"
        animating={loading}
        color={materialTheme.COLORS.BRAND}
        style={{ margin: 10, flex: 1, alignSelf: "center" }}
      />
    </View>
  ) : billingNotice ? (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Image
          source={require("../../assets/images/checked2.png")}
          style={{ height: 40, width: 40 }}
        />
        <Text
          style={{
            color: materialTheme.COLORS.CAPTION,
            fontSize: 25,
            textAlign: "center",
          }}
        >
          You have been completed task !
        </Text>
      </View>
    </View>
  ) : (
    <View style={{ flex: 7, backgroundColor: "transparent" }}>
      <View
        style={{
          flex: 0.65,
          elevation: 0,
          paddingHorizontal: 0,
        }}
      >
        <PDFView {...props} url={url} />
      </View>
      <Text
        style={{
          marginHorizontal: 15,
          marginVertical: 15,
          color: materialTheme.COLORS.ERROR,
          textAlign: "center",
        }}
      >
        ** Please print the form, sign and upload. **
      </Text>
      <View style={{ flex: 0.35, paddingHorizontal: 0, alignItems: "center" }}>
        <UploadSignature
          {...props}
          departmentId={departmentId}
          practiceId={practiceId}
          patientId={patientId}
          patientName={patientName}
          loading={() => setLoading(!loading)}
          name="SignBillingInfo"
        />
      </View>
    </View>
  );
};
export default SignBillingInfo;
