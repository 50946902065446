import React, { useEffect, useState } from "react";
import {
  Platform,
  StyleSheet,
  Image,
  TouchableOpacity,
  Dimensions,
  RefreshControl,
  ActivityIndicator,
  Alert,
  View,
} from "react-native";
import { Card, Text } from "react-native-paper";
import { materialTheme } from "../constants/";
import { Button } from "../ui/";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getAthenaApi } from "../graphql/queries";
import { putAthenaApi } from "../graphql/mutations";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import PersonalInfoForm1 from "./PersonalInfoForm1";
import PersonalInfoForm2 from "./PersonalInfoForm2";
import PersonalInfoForm3 from "./PersonalInfoForm3";
import PersonalInfoForm4 from "./PersonalInfoForm4";
import PersonalInfoForm5 from "./PersonalInfoForm5";

import { API, graphqlOperation } from "aws-amplify";
import config from "../../aws-exports";

API.configure(config);

const { height, width } = Dimensions.get("window");

const iPhoneX = () =>
  Platform.OS === "ios" &&
  (height === 812 || width === 812 || height === 896 || width === 896);

const PersonalInfo = (props) => {
  // let appointmentId = props.route.params.appointmentId
  const [formPages, setFormPages] = useState([
    "Please update your personal information",
    "Personal info",
    "Emergency contact",
    "Driver License Details",
    "Additional info",
  ]);
  const [index, setIndex] = useState(0);
  const [task, setTask] = useState("");
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(true);
  const [ssn, setSsn] = useState("");

  const [personalInfo, setPersonalInfo] = useState({
    id: null,
    firstName: "",
    lastName: "",
    socialSecurityNumber: "",
    address: "",
    city: "",
    zipCode: "",
    state: "",
    country: "",
    dateOfBirth: "",
    gender: "",
    homePhone: "",
    mobilePhone: "",
    email: "",
    conatactPreference: "",
    emergencyCantactName: "",
    emergencyCantactPhone: "",
    marritalStatus: "",
    language: "",
    ethnicity: "",
    race: "",
    consenToCall: false,
    driversLicenseNumber: "",
    driverLienseState: "",
    driverLicenseExpirationDate: "",
  });
  const [active, setActive] = useState({
    firstName: false,
    lastName: false,
    socialSecurityNumber: false,
    address: false,
    city: false,
    zipCode: false,
    state: false,
    country: false,
    dateOfBirth: false,
    gender: false,
    homePhone: false,
    mobilePhone: false,
    email: false,
    conatactPreference: false,
    emergencyCantactName: false,
    emergencyCantactPhone: false,
    marritalStatus: false,
    language: false,
    ethnicity: false,
    race: false,
    consenToCall: false,
    driversLicenseNumber: false,
    driverLienseState: false,
    driverLicenseExpirationDate: false,
  });
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    socialSecurityNumber: false,
    address: false,
    city: false,
    zipCode: false,
    state: false,
    country: false,
    dateOfBirth: false,
    gender: false,
    homePhone: false,
    mobilePhone: false,
    email: false,
    conatactPreference: false,
    emergencyCantactName: false,
    emergencyCantactPhone: false,
    marritalStatus: false,
    language: false,
    ethnicity: false,
    race: false,
    consenToCall: false,
    driversLicenseNumber: false,
    driverLienseState: false,
    driverLicenseExpirationDate: false,
  });

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = React.useCallback(() => {
    getPatientInfo();
  }, []);

  // console.log("patient-data", personalInfo)

  const getPatientInfo = async () => {
    setLoading(true);
    let token = await AsyncStorage.getItem("Token");
    let emrdata = await AsyncStorage.getItem("EmrApptIds");
    let parsedData = JSON.parse(emrdata);
    let practiceId = parsedData.fId;
    let patientId = parsedData.pId;
    let url = `https://api.platform.athenahealth.com/v1/${practiceId}/patients/${patientId}`;
    console.log("patientUrl", url);
    await API.graphql(
      graphqlOperation(getAthenaApi, { apiUrl: url, token: token })
    )
      .then((res) => {
        let response = res.data.getAthenaApi.response;
        let json = JSON.parse(response);
        console.log("patient-info", json);
        if (json.error) {
          alert(json.error);
        } else {
          let obj = json[0];
          setPersonalInfo({
            firstName: obj.firstname ? obj.firstname : "",
            lastName: obj.lastname ? obj.lastname : "",
            socialSecurityNumber: obj.ssn ? obj.ssn : "",
            address: obj.address1 ? obj.address1 : "",
            city: obj.city ? obj.city : "",
            zipCode: obj.zip ? obj.zip : "",
            state: obj.state ? obj.state : "",
            country: obj.countrycode ? obj.countrycode : "",
            dateOfBirth: obj.dob ? obj.dob : "",
            gender: obj.sex ? obj.sex : "",
            homePhone: obj.homephone ? obj.homephone : "",
            mobilePhone: obj.mobilephone ? obj.mobilephone : "",
            email: obj.email ? obj.email : "",
            conatactPreference: obj.contactpreference
              ? obj.contactpreference
              : "",
            emergencyCantactName: obj.contactname ? obj.contactname : "",
            emergencyCantactPhone: obj.contactmobilephone
              ? obj.contactmobilephone
              : "",
            marritalStatus: obj.maritalstatusname ? obj.maritalstatusname : "",
            language: obj.language6392code ? obj.language6392code : "",
            ethnicity: obj.ethnicity ? obj.ethnicity : "",
            race: obj.race ? obj.race : "",
            consenToCall: obj.consenttocall ? obj.consenttocall : false,
            driversLicenseNumber: obj.driverslicensenumber
              ? obj.driverslicensenumber
              : "",
            driverLienseState: obj.driverslicensestateid
              ? obj.driverslicensestateid
              : "",
            driverLicenseExpirationDate: obj.driverslicenseexpirationdate
              ? obj.driverslicenseexpirationdate
              : "",
          });
          setSsn(obj.ssn ? obj.ssn : "");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error while fetching patient Information", err);
        setLoading(false);
      });
  };

  const updatePatient = async () => {
    if (Object.keys(errors).length !== 0) {
      alert("Please fill required fields");
    } else {
      setLoading(true);
      let token = await AsyncStorage.getItem("Token");
      let emrdata = await AsyncStorage.getItem("EmrApptIds");
      let parsedData = JSON.parse(emrdata);
      let practiceId = parsedData.fId;
      let patientId = parsedData.pId;
      let url = `https://api.platform.athenahealth.com/v1/${practiceId}/patients/${patientId}`;
      if (index === 0) {
        var apiBody = `firstname=${personalInfo.firstName}&lastname=${
          personalInfo.lastName
        }&${
          personalInfo.socialSecurityNumber === ssn
            ? undefined
            : `ssn=${personalInfo.socialSecurityNumber}`
        }&address1=${personalInfo.address}&city=${personalInfo.city}&state=${
          personalInfo.state
        }&countryCode=${personalInfo.country}&zip=${personalInfo.zipCode}`;
      } else if (index === 1) {
        var apiBody = `dob=${personalInfo.dateOfBirth}&sex=${personalInfo.gender}&homephone=${personalInfo.homePhone}&mobilephone=${personalInfo.mobilePhone}&email=${personalInfo.email}&contactpreference=${personalInfo.conatactPreference}`;
      } else if (index === 2) {
        var apiBody = `contactname=${personalInfo.emergencyCantactName}&contactmobilephone=${personalInfo.emergencyCantactPhone}`;
      } else if (index === 4) {
        var apiBody = `maritalstatus=${personalInfo.marritalStatus}&consenttocall=${personalInfo.consenToCall}&race=${personalInfo.race}&ethnicitycode=${personalInfo.ethnicity}&language6392code=${personalInfo.language}`;
      } else if (index === 3) {
        var apiBody = `driverslicensenumber=${personalInfo.driversLicenseNumber}&driverslicensestateid=${personalInfo.driverLienseState}&driverslicenseexpirationdate=${personalInfo.driverLicenseExpirationDate}`;
      }

      await API.graphql(
        graphqlOperation(putAthenaApi, {
          apiUrl: url,
          body: apiBody,
          token: token,
        })
      )
        .then((res) => {
          console.log("patient data updated");
          setLoading(false);
          alert("patient data updated");
          if (index === 4) {
            props.navigation.navigate("Appointments");
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          alert(err);
        });
    }
  };
  const handleChange = (text, name) => {
    setPersonalInfo({ ...personalInfo, [name]: text });
  };
  const toggleActive = (name) => {
    setActive({ [name]: true });
  };
  const onBlur = (name) => {
    setTouched({ ...touched, [name]: true });
  };

  const Validation = () => {
    const errors = {};
    let date =
      /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/g;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (index == 0) {
      if (personalInfo.socialSecurityNumber == "") {
        errors.socialSecurityNumber = "Please enter ssn number";
      }
      if (personalInfo.firstName == "") {
        errors.firstName = "Please enter First name";
      }
      if (personalInfo.lastName == "") {
        errors.lastName = "Please enter Last name";
      }
      if (personalInfo.address == "") {
        errors.address = "Please enter Address";
      }
      if (personalInfo.city == "") {
        errors.city = "Please enter City";
      }
      if (personalInfo.zipCode) {
        if (
          personalInfo.zipCode.length < 5 ||
          (personalInfo.zipCode.length > 5 && personalInfo.zipCode.length < 9)
        ) {
          errors.zipCode = "Please enter valid Zipcode";
        }
      } else {
        errors.zipCode = "Please enter Zipcode";
      }
      if (personalInfo.state == "") {
        errors.state = "Please select State";
      }
    } else if (index == 1) {
      if (personalInfo.email) {
        if (!emailRegex.test(personalInfo.email)) {
          errors.email = " Please enter valid Email";
        }
      }
      if (personalInfo.dateOfBirth == "") {
        errors.dateOfBirth = "Please enter Date of Birth";
      } else {
        if (!date.test(personalInfo.dateOfBirth)) {
          errors.dateOfBirth =
            "Please enter valid date mm/dd/yyyy, ex:01/01/2001";
        }
        if (new Date(personalInfo.dateOfBirth) > new Date()) {
          errors.dateOfBirth = "Please enter valid date of birth";
        }
      }
      if (personalInfo.gender == "") {
        errors.gender = "Please select Gender";
      }
      if (personalInfo.homePhone == "") {
        errors.homePhone = "Please enter home phone";
      }
      if (personalInfo.mobilePhone == "") {
        errors.mobilePhone = "Please enter mobile phone";
      }
    } else if (index == 2) {
      if (personalInfo.emergencyCantactPhone == "") {
        errors.emergencyCantactPhone = "Please enter phone number";
      }
      if (personalInfo.emergencyCantactName == "") {
        errors.emergencyCantactName = "Please enter name";
      }
    } else if (index == 3) {
      if (personalInfo.driverLienseState == "") {
        errors.driverLienseState = "Please select License State";
      }
      if (personalInfo.driverLicenseExpirationDate == "") {
        errors.driverLicenseExpirationDate = "Please select exp date";
      }
      if (personalInfo.driversLicenseNumber == "") {
        errors.driversLicenseNumber = "Please enter license number";
      }
    } else if (index == 4) {
      if (personalInfo.marritalStatus == "") {
        errors.marritalStatus = "Please select maritial status";
      }
      if (personalInfo.language == "") {
        errors.language = "Please select language";
      }
      if (personalInfo.consenToCall == "") {
        errors.consenToCall = "Please select consent to call";
      }
      if (personalInfo.race == "") {
        errors.race = "Please select race";
      }
    }
    return {
      errors,
    };
  };

  const onClear = () => {
    setPersonalInfo({
      firstName: "",
      lastName: "",
      socialSecurityNumber: "",
      address: "",
      city: "",
      zipCode: "",
      state: "",
      country: "",
      dateOfBirth: "",
      gender: "",
      homePhone: "",
      mobilePhone: "",
      email: "",
      conatactPreference: "",
      emergencyCantactName: "",
      emergencyCantactPhone: "",
      marritalStatus: "",
      language: "",
      ethnicity: "",
      race: "",
      consenToCall: false,
    });
    setIndex(0);
  };
  const onNextClick = () => {
    if (Object.keys(errors).length === 0) {
      setIndex(index + 1);
    } else {
      if (index == 0) {
        setTouched({
          ...touched,
          firstName: true,
          lastName: true,
          socialSecurityNumber: true,
          address: true,
          city: true,
          zipCode: true,
          state: true,
          country: true,
        });
      } else if (index == 1) {
        setTouched({
          ...touched,
          dateOfBirth: true,
          gender: true,
          homePhone: true,
          mobilePhone: true,
          email: true,
        });
      } else if (index == 2) {
        setTouched({
          ...touched,
          emergencyCantactName: true,
          emergencyCantactPhone: true,
        });
      } else if (index == 4) {
        setTouched({
          ...touched,
          marritalStatus: true,
          language: true,
          ethnicity: true,
          race: true,
          consenToCall: true,
        });
      } else if (index == 3) {
        setTouched({
          ...touched,
          driversLicenseNumber: true,
          driverLienseState: true,
          driverLicenseExpirationDate: true,
        });
      }
      Alert.alert("Please fill required fields");
    }
  };

  const { errors } = Validation();
  console.log(errors);
  return loading ? (
    <View style={{ flex: 1 }}>
      <View style={styles.apptsSection}>
        <View style={styles.appointment}>
          <Text style={styles.buttonText}>Loading ...</Text>
        </View>
      </View>
    </View>
  ) : (
    <View style={{ flex: 1 }}>
      <KeyboardAwareScrollView
        behavior="padding"
        style={{ flex: 1 }}
        contentContainerStyle={{
          width: width >= 768 ? width * 0.5 : width,
          alignSelf: "center",
          height: "auto",
        }}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        enabled
      >
        <Text style={styles.formHeaderText}>{formPages[index]}:</Text>
        {index == 0 ? (
          <PersonalInfoForm1
            personalInfo={personalInfo}
            handleChange={handleChange}
            toggleActive={toggleActive}
            onBlur={onBlur}
            active={active}
            touched={touched}
            errors={errors}
            styles={styles}
          />
        ) : index == 1 ? (
          <PersonalInfoForm2
            personalInfo={personalInfo}
            handleChange={handleChange}
            toggleActive={toggleActive}
            onBlur={onBlur}
            active={active}
            touched={touched}
            errors={errors}
            styles={styles}
          />
        ) : index == 2 ? (
          <PersonalInfoForm3
            personalInfo={personalInfo}
            handleChange={handleChange}
            toggleActive={toggleActive}
            onBlur={onBlur}
            active={active}
            touched={touched}
            errors={errors}
            styles={styles}
          />
        ) : index == 3 ? (
          <PersonalInfoForm5
            personalInfo={personalInfo}
            handleChange={handleChange}
            toggleActive={toggleActive}
            onBlur={onBlur}
            active={active}
            touched={touched}
            errors={errors}
            styles={styles}
          />
        ) : (
          <PersonalInfoForm4
            personalInfo={personalInfo}
            handleChange={handleChange}
            toggleActive={toggleActive}
            onBlur={onBlur}
            active={active}
            touched={touched}
            errors={errors}
            styles={styles}
          />
        )}
        <View style={styles.eventButtonsBox}>
          {index > 0 ? (
            <TouchableOpacity
              onPress={() => setIndex(index - 1)}
              style={styles.prev}
            >
              <Image
                source={require("../../assets/icons/lessthan.png")}
                style={styles.image}
              />
              <Text style={styles.buttonText}>PREV</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity style={styles.prev} disabled={true}>
              <Image
                source={require("../../assets/icons/lessthan.png")}
                style={styles.image}
              />
              <Text style={styles.disableButtonText}>PREV</Text>
            </TouchableOpacity>
          )}
          {
            <TouchableOpacity onPress={updatePatient}>
              <Text style={styles.buttonText}>UPDATE</Text>
            </TouchableOpacity>
          }
          {index < 4 ? (
            <TouchableOpacity onPress={onNextClick} style={styles.next}>
              <Text style={styles.buttonText}>NEXT</Text>
              <Image
                source={require("../../assets/icons/forward.png")}
                style={styles.image}
              />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity style={styles.prev} disabled={true}>
              <Text style={styles.disableButtonText}>NEXT</Text>
              <Image
                source={require("../../assets/icons/forward.png")}
                style={styles.image}
              />
            </TouchableOpacity>
          )}
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 5,
  },
  formHeaderText: {
    fontSize: 25,
    color: materialTheme.COLORS.PRIMARY,
    textAlign: "center",
    paddingTop: 20,
    fontWeight: "bold",
    marginVertical: 20,
  },
  eventButtonsBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    paddingBottom: iPhoneX() ? 13 * 1.5 : null,
    marginBottom: 20,
  },
  next: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  disablePrev: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  prev: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontSize: 22,
    fontWeight: "bold",
    color: materialTheme.COLORS.PRIMARY,
  },
  disableButtonText: {
    fontSize: 22,
    fontWeight: "bold",
    color: materialTheme.COLORS.BLOCK,
  },

  image: {
    height: 40,
    width: 40,
  },
  image: {
    height: 40,
    width: 40,
  },
  button: {
    paddingHorizontal: 22,
    backgroundColor: materialTheme.COLORS.BUTTON_COLOR,
  },
  input: {
    borderRadius: 0,
    height: 16 * 2.75,
    backgroundColor: "transparent",
    borderWidth: 0.25,
    marginTop: 10,
    borderColor: materialTheme.COLORS.DEFAULT,
    color: materialTheme.COLORS.MUTED,
  },
  input2: {
    borderRadius: 4,
    height: 16 * 2.75,
    backgroundColor: "transparent",
    borderWidth: 0.25,
    marginTop: 10,

    borderColor: materialTheme.COLORS.DEFAULT,
    color: materialTheme.COLORS.MUTED,
  },
  inputActive: {
    borderBottomColor: materialTheme.COLORS.ACTIVE,
  },
  maskinput: {
    height: 16 * 2.75,
    borderRadius: 4,
    borderWidth: 1,
    marginTop: 10,
    paddingLeft: 16,
    borderColor: materialTheme.COLORS.DEFAULT,
    color: materialTheme.COLORS.INPUT_TEXT,
  },
  maskInputActive: {
    borderBottomWidth: 2,
    borderBottomColor: materialTheme.COLORS.ACTIVE,
  },
  cityandzip: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  label: {
    fontSize: 17,
    marginTop: 10,
    color: materialTheme.COLORS.LABEL,
  },
  label1: {
    fontSize: 17,
    marginTop: 10,
    color: materialTheme.COLORS.LABEL,
  },
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 4,
    color: "black",
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: "purple",
    borderRadius: 8,
    color: "black",
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  modaldropdownText: {
    fontSize: 15,
    paddingLeft: 10,
    color: materialTheme.COLORS.ACTIVE,
  },
  dropdownStyles: {
    height: 44,
    justifyContent: "center",
    marginVertical: 10,
    borderColor: materialTheme.COLORS.DEFAULT,
  },

  checkbox: {
    color: "red",
    marginVertical: 10,
  },
  checkboxLabel: {
    fontSize: 17,
  },
  errorText: {
    width: width * 0.9,
    flexWrap: "wrap",
    fontSize: 15,
    marginTop: 5,
    color: materialTheme.COLORS.ERROR,
  },
  apptsSection: {
    width: width >= 768 ? width * 0.5 : width,
    justifyContent: "center",
  },
  appointment: {
    width: width >= 768 ? width * 0.5 : width - 30,
    backgroundColor: materialTheme.COLORS.BLOCK,
    borderRadius: 10,
    justifyContent: "flex-start",
    flexDirection: "column",
    marginVertical: 5,
    paddingLeft: 15,
    paddingVertical: 15,
  },
  time: {
    flex: 1,
    fontSize: 18,
    fontWeight: "800",
    color: materialTheme.COLORS.CAPTION,
    flexWrap: "wrap",
    marginVertical: 5,
  },
});
export default PersonalInfo;
