import React, { useEffect, useContext } from "react";
import {
  Dimensions,
  Image,
  SafeAreaView,
  StyleSheet,
  View,
} from "react-native";
import { Card, Text } from "react-native-paper";
const { width } = Dimensions.get("window");
import { materialTheme } from "../constants/";
import { Button } from "../ui/";

const Dashboard = (props) => {
  return (
    <View center style={styles.container}>
      <View style={[styles.section, { flex: 0.3 }]}>
        <Image
          source={require("../../assets/images/logo.png")}
          style={styles.logo}
        />
      </View>
      <View style={[styles.section, { flex: 0.1 }]}>
        <Text style={styles.welcomeText}>Welcome to MeritCare</Text>
      </View>
      <View style={[styles.section, { flex: 0.25, paddingHorizontal: 15 }]}>
        <Text style={styles.notes}>
          Using MeritCare, you can view schedule your appointments for providers
          you already been working with.
        </Text>
      </View>
      <View
        style={[
          {
            width: width >= 768 ? width * 0.5 : width,
            flex: 0.3,
            alignSelf: "center",
            paddingHorizontal: 15,
          },
        ]}
      >
        <Button
          style={styles.button}
          onPress={() => props.navigation.navigate("Appointments")}
        >
          My Appointments
        </Button>
        <Button
          style={styles.button}
          onPress={() => props.navigation.navigate("MyInfo")}
        >
          My Info
        </Button>
      </View>
      <SafeAreaView>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderTopWidth: 0.5,
            width: width,
          }}
        >
          <Text style={styles.footer}>Meritore TeleCheck</Text>
        </View>
      </SafeAreaView>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  section: {
    width: width,
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 300,
    height: 100,
  },
  welcomeText: {
    fontSize: 25,
    color: materialTheme.COLORS.PRIMARY,
  },
  notes: {
    fontSize: 20,
    color: materialTheme.COLORS.CAPTION,
    fontWeight: "500",
    textAlign: "justify",
  },
  button: {
    height: 48,
    justifyContent: "center",
    marginTop: 10,
    marginVertical: 10,
    backgroundColor: materialTheme.COLORS.BUTTON_COLOR,
  },
  footer: {
    fontSize: 13,
    color: materialTheme.COLORS.CAPTION,
    fontWeight: "500",
    alignSelf: "center",
    marginVertical: 5,
  },
});
export default Dashboard;
