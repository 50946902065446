import React from "react";
import { withNavigation } from "@react-navigation/compat";
import {
  TouchableOpacity,
  StyleSheet,
  Platform,
  Dimensions,
  Image,
  SafeAreaView,
} from "react-native";
import { Appbar } from "react-native-paper";
import materialTheme from "../constants/Theme";
import Tabs from "./Tabs";

const { height, width } = Dimensions.get("window");
const iPhoneX = () =>
  Platform.OS === "ios" &&
  (height === 812 || width === 812 || height === 896 || width === 896);

// const ChatButton = ({ isWhite, style, navigation }) => (
//   <TouchableOpacity
//     style={[styles.button, style]}
//     onPress={() => navigation.navigate("Chat")}
//   >
//     <Icon family="GalioExtra" size={16} name="chat-33" color={"WHITE"} />
//     <Block middle style={styles.notify} />
//   </TouchableOpacity>
// )

// const BasketButton = ({ isWhite, style, navigation }) => (
//   <TouchableOpacity
//     style={[styles.button, style]}
//     onPress={() => navigation.navigate("Cart")}
//   >
//     <Icon family="GalioExtra" size={16} name="basket-simple" color={"WHITE"} />
//     <Block middle style={styles.notify} />
//   </TouchableOpacity>
// )

// const SearchButton = ({ isWhite, style, navigation }) => (
//   <TouchableOpacity
//     style={[styles.button, style]}
//     onPress={() => navigation.navigate("Search")}
//   >
//     <Icon size={16} family="entypo" name="magnifying-glass" color={"WHITE"} />
//   </TouchableOpacity>
// )
const AddButton = ({ isWhite, style, navigation, route, data }) => (
  <TouchableOpacity
    style={[styles.button, style]}
    onPress={() => navigation.navigate(route, { data: data })}
  >
    <Image
      source={require("../../assets/icons/add.png")}
      style={styles.image}
    />
    {/* <Block middle style={styles.notify} /> */}
  </TouchableOpacity>
);

const Header = (props) => {
  const {
    white,
    back,
    title,
    navigation,
    data,
    backRoute,
    add,
    tabs,
    tabIndex,
    options,
    search,
    transparent,
  } = props;
  console.log(props);
  const handleLeftPress = () => {
    if (back) {
      console.log(backRoute, props, "hai,,,,,,,12345");
      if (backRoute) {
        console.log(backRoute, "hai,,,,,,,");
        navigation.navigate("Dashboard");
      } else navigation.goBack();
    } else navigation.openDrawer();
    // return (back ? navigation.goBack() : navigation.openDrawer());
  };

  const renderRight = () => {
    // const { options } = scene.descriptor;
    // const routeName = options.headerTitle; // wip

    if (title === "Title") {
      return [
        // <ChatButton key='chat-search' navigation={navigation} isWhite={white} />,
        // <BasketButton key='basket-search' navigation={navigation} isWhite={white} />
      ];
    }
    if (add && add == "Provider Detail") {
      return [
        <AddButton
          key="add"
          navigation={navigation}
          isWhite={white}
          data={data}
          route="NewAppointment"
        />,
      ];
    }

    switch (title) {
      case "Select Provider":
        return [
          // <AddButton
          //   key="add"
          //   navigation={navigation}
          //   isWhite={white}
          //   data={data}
          //   route="AddProvider"
          // />,
        ];
      case "Provider Detail":
        return [
          // <AddButton
          //   key="add"
          //   navigation={navigation}
          //   isWhite={white}
          //   data={data}
          //   route="NewAppointment"
          // />,
        ];
      case "About":
      case "Appointments":
      case "Inbox":
      case "My Health":
      case "My Tasks":
      case "Payments":
      case "Notifications":
      case "Privacy":
      case "Profile":
      case "Settings":
      case "Product":
      default:
        break;
    }
  };

  const renderTabs = () => {
    const defaultTab = tabs && tabs[0] && tabs[0].id;

    if (!tabs) return null;

    return (
      <Tabs
        data={tabs || []}
        initialIndex={tabIndex || defaultTab}
        onChange={(id) => navigation.setParams({ tabId: id })}
      />
    );
  };

  const renderHeader = () => {
    if (search || tabs || options) {
      return tabs ? renderTabs() : null;
    }
    return null;
  };

  const noShadow = ["Search", "Profile"].includes(title);
  const headerStyles = [
    !noShadow ? styles.shadow : null,
    transparent ? { backgroundColor: "rgba(0,0,0,0)" } : null,
  ];
  let active = "";
  return (
    <SafeAreaView style={{ backgroundColor: "white" }}>
      {/* <NavBar
            back={back}
            title={title == "Provider Detail" ? data.providerName : title}
            style={styles.navbar}
            transparent={transparent}
            right={this.renderRight()}
            rightStyle={{ alignItems: "center" }}
            leftStyle={{ paddingTop: 3, flex: 0.3 }}
            leftIconName={back ? null : "navicon"}
            leftIconSize={30}
            // leftIconFamily="font-awesome"
            leftIconColor={white ? theme.COLORS.WHITE : theme.COLORS.ICON}
            titleStyle={[
              styles.title,
              { color: theme.COLORS[white ? "WHITE" : "ICON"] },
            ]}
            onLeftPress={this.handleLeftPress}
          /> */}

      <Appbar.Header
        style={{
          backgroundColor: "white",
          elevation: 0,
          borderBottomWidth: 1,
          borderBottomColor: materialTheme.COLORS.MUTED,
        }}
      >
        {back ? (
          <Appbar.BackAction
            size={Platform.OS == "web" ? 23 : 13}
            onPress={() => handleLeftPress()}
          />
        ) : (
          <Appbar.Action
            icon="menu"
            onPress={() => {
              handleLeftPress();
            }}
          />
        )}
        <Appbar.Content
          title={title}
          add={add}
          // title={title == "Provider Detail" ? data.providerName : title}
          style={{ alignItems: "flex-start" }}
        />
        {renderRight()}
      </Appbar.Header>
      {renderHeader()}
    </SafeAreaView>
  );
};

export default withNavigation(Header);

const styles = StyleSheet.create({
  button: {
    padding: 12,
    position: "relative",
  },
  title: {
    width: "100%",
    fontSize: 16,
    fontWeight: "bold",
  },
  navbar: {
    paddingVertical: 0,
    // paddingBottom: iPhoneX() ? theme.SIZES.BASE * 1.5 : theme.SIZES.BASE,
    // paddingTop: iPhoneX() ? theme.SIZES.BASE * 4 : theme.SIZES.BASE,
    zIndex: 5,
    height: 60,
  },
  shadow: {
    backgroundColor: "white",
    shadowColor: "black",
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 6,
    shadowOpacity: 0.2,
    elevation: 3,
  },
  notify: {
    backgroundColor: materialTheme.COLORS.LABEL,
    borderRadius: 4,
    height: 10 / 2,
    width: 10 / 2,
    position: "absolute",
    top: 8,
    right: 8,
  },
  header: {
    backgroundColor: "white",
  },
  divider: {
    borderRightWidth: 0.3,
    borderRightColor: materialTheme.COLORS.MUTED,
  },
  search: {
    height: 48,
    width: width - 32,
    marginHorizontal: 16,
    borderWidth: 1,
    borderRadius: 3,
  },
  tabs: {
    marginBottom: 24,
    marginTop: 10,
  },
  tab: {
    backgroundColor: "transparent",
    width: width * 0.5,
    borderRadius: 0,
    borderWidth: 0,
    height: 24,
    elevation: 0,
  },
  tabTitle: {
    lineHeight: 19,
    fontWeight: "300",
  },
  image: {
    height: 25,
    width: 25,
  },
});
