import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import {
  ActivityIndicator,
  Dimensions,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  RefreshControl,
  Image,
  TouchableOpacity,
  View,
} from "react-native";
import { Button } from "../ui";
import { Text } from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { materialTheme } from "../constants";
import { fetchUser } from "../api/users";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Header } from "../ui";
import { NavigationActions } from "@react-navigation/compat";
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";

import config from "../../aws-exports";

import { getAthenaApi } from "../graphql/queries";

API.configure(config);

const { height, width } = Dimensions.get("window");

const Appointments = (props) => {
  const { navigation } = props;
  const [nextAppointment, setNextAppointment] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [futureAppointments, setFutureAppointments] = useState([]);
  const [pastAppointments, setPastAppointments] = useState([]);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = React.useCallback(() => {
    futureappointments();
  }, []);

  futureAppointments?.sort((a, b) => {
    if (new Date(a.date) > new Date(b.date)) return 1;
    if (new Date(a.date) < new Date(b.date)) return -1;
    return 0;
  });

  pastAppointments?.sort((a, b) => {
    if (new Date(a.date) < new Date(b.date)) return 1;
    if (new Date(a.date) > new Date(b.date)) return -1;
    return 0;
  });

  const futureappointments = async () => {
    let token = await AsyncStorage.getItem("Token");
    let emrdata = await AsyncStorage.getItem("EmrApptIds");
    let parsedData = JSON.parse(emrdata);
    let practiceId = parsedData.fId;
    let patientId = parsedData.pId;
    let url = `https://api.platform.athenahealth.com/v1/${practiceId}/patients/${patientId}/appointments`;
    await API.graphql(
      graphqlOperation(getAthenaApi, { apiUrl: url, token: token })
    )
      .then((res) => {
        let response = res.data.getAthenaApi.response;
        let data = JSON.parse(response);
        if (data.error) {
          alert(data.error);
        } else {
          setFutureAppointments(data.appointments);
          fetchAppointments();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error while fetching appointments", err);
        setLoading(false);
      });
  };

  const fetchPastAppointments = () => {
    try {
      setLoading(true);
      let data = appointments?.filter((i) => {
        let date =
          moment(i.date).format("MM/DD/YYYY") < moment().format("MM/DD/YYYY");
        return date;
      });
      setPastAppointments(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const fetchAppointments = async () => {
    setLoading(true);
    let token = await AsyncStorage.getItem("Token");
    let emrdata = await AsyncStorage.getItem("EmrApptIds");
    let parsedData = JSON.parse(emrdata);
    let practiceId = parsedData.fId;
    let patientId = parsedData.pId;
    let url = `https://api.platform.athenahealth.com/v1/${practiceId}/patients/${patientId}/appointments?showpast=true&showcancelled=true`;
    await API.graphql(
      graphqlOperation(getAthenaApi, { apiUrl: url, token: token })
    )
      .then((res) => {
        let response = res.data.getAthenaApi.response;
        let data = JSON.parse(response);
        if (data.error) {
          alert(data.error);
        } else {
          setAppointments(data.appointments);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error while fetching appointments", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPastAppointments();
  }, [appointments]);

  return (
    <View style={{ height: height, backgroundColor: "white" }}>
      <ScrollView>
        <KeyboardAwareScrollView
          keyboardShouldPersistTaps="handled"
          behavior="padding"
          contentContainerStyle={{ flexGrow: 1 }}
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={onRefresh} />
          }
          enabled
        >
          <View style={styles.tabs}>
            <Button
              onPress={() => setTab(0)}
              style={tab === 1 ? styles.secondaryButton : styles.button}
            >
              Future
            </Button>
            <Button
              onPress={() => setTab(1)}
              style={tab === 1 ? styles.button : styles.secondaryButton}
            >
              Past
            </Button>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.appointmentHeader1}>Appointments</Text>
            {loading ? (
              <View style={styles.apptsSection}>
                <View style={styles.fappointment}>
                  <Text style={styles.time}>Loading ...</Text>
                </View>
              </View>
            ) : (
              <View style={styles.apptsSection}>
                {tab === 1 ? (
                  pastAppointments ? (
                    pastAppointments.map((appointment, index) => {
                      return (
                        <TouchableOpacity
                          onPress={() =>
                            props.navigation.navigate("AppointmentDetail", {
                              appointment: appointment,
                              appointmentType: "past",
                            })
                          }
                          key={index}
                        >
                          <View style={styles.fappointment}>
                            <Text style={styles.time}>
                              {moment(appointment.date).format("DD, MMMM YYYY")}
                            </Text>
                            <Text style={styles.appointmentHeader}>
                              {appointment.patientappointmenttypename}
                            </Text>
                            <Text style={styles.time}>
                              {appointment.starttime}
                            </Text>
                          </View>
                        </TouchableOpacity>
                      );
                    })
                  ) : (
                    <View style={styles.fappointment}>
                      <Text style={styles.time}>No Appointments</Text>
                    </View>
                  )
                ) : futureAppointments ? (
                  futureAppointments.map((appointment, index) => {
                    return (
                      <TouchableOpacity
                        onPress={() =>
                          props.navigation.navigate("AppointmentDetail", {
                            appointment: appointment,
                            appointmentType: "future",
                          })
                        }
                        key={index}
                      >
                        <View
                          style={
                            appointment.appointmentstatus === "f"
                              ? styles.appointment
                              : styles.fappointment
                          }
                        >
                          <Text style={styles.time}>
                            {moment(appointment.date).format("DD, MMMM YYYY")}
                          </Text>
                          <Text style={styles.appointmentHeader}>
                            {appointment.patientappointmenttypename}
                          </Text>
                          <Text style={styles.time}>
                            {appointment.starttime}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    );
                  })
                ) : (
                  <View style={styles.fappointment}>
                    <Text style={styles.time}>No Appointments</Text>
                  </View>
                )}
              </View>
            )}
          </View>
        </KeyboardAwareScrollView>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  apptsSection: {
    justifyContent: "center",
    paddingHorizontal: 15,
  },
  appointment: {
    backgroundColor: materialTheme.COLORS.BRAND,
    borderRadius: 10,
    justifyContent: "flex-start",
    flexDirection: "column",
    marginVertical: 5,
    paddingLeft: 15,
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  fappointment: {
    backgroundColor: materialTheme.COLORS.BLOCK,
    borderRadius: 10,
    justifyContent: "flex-start",
    flexDirection: "column",
    marginVertical: 5,
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  time: {
    flex: 1,
    fontSize: 18,
    fontWeight: "800",
    color: materialTheme.COLORS.CAPTION,
    flexWrap: "wrap",
    marginVertical: 5,
  },
  appointmentHeader: {
    fontSize: 12,
    color: materialTheme.COLORS.CAPTION,
    zIndex: 10,
    alignSelf: "flex-start",
    marginVertical: 5,
  },
  appointmentHeader2: {
    fontSize: 22,
    marginVertical: 10,
    marginHorizontal: 20,
  },
  appointmentHeader1: {
    fontSize: 34,
    marginVertical: 20,
    fontWeight: "bold",
    alignSelf: "center",
    color: materialTheme.COLORS.PRIMARY,
  },
  button: {
    height: 48,
    justifyContent: "center",
    backgroundColor: materialTheme.COLORS.BUTTON_COLOR,
    color: materialTheme.COLORS.PRIMARY,
  },
  secondaryButton: {
    height: 48,
    justifyContent: "center",
    backgroundColor: "#A6A6A6",
    color: "#A6A6A6",
  },
  pendingBadge: {
    height: 25,
    width: 25,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "red",
    borderRadius: 40,
    marginRight: 15,
    padding: 5,
  },
  tabs: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 15,
  },
});
export default Appointments;
