import React, { useState } from "react";
import {
  Alert,
  Dimensions,
  StyleSheet,
  Platform,
  ActivityIndicator,
  Image,
  Keyboard,
  SafeAreaView,
  TouchableOpacity,
  View,
} from "react-native";

import { Card, Text, TextInput as Input } from "react-native-paper";

import { Auth } from "aws-amplify";
import Amplify from "@aws-amplify/core";
import config from "../../aws-exports";
import { Button } from "../ui/";
import { materialTheme } from "../constants/";
import { HeaderHeight } from "../constants/utils";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { TextInputMask } from "react-native-masked-text";

const { width } = Dimensions.get("window");
Amplify.configure(config);

const ChangePassword = (props) => {
  const { navigation } = props;
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
    otp: "",
    isActiveOtp: false,
    confirmPassword: "",
    error: "",
  });
  const [active, setActive] = useState({
    email: false,
    password: false,
    confirmPassword: false,
    otp: false,
  });
  const [touched, setTouched] = useState({
    email: false,
    password: false,
    confirmPassword: false,
    otp: false,
  });

  const handleChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };
  const toggleActive = (name) => {
    setActive({ [name]: true });
  };
  const onTouched = (name) => {
    setTouched({ ...touched, [name]: true });
  };
  const validate = () => {
    // console.log(active.otp)
    const errors = {};
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (user.email != "") {
      if (!emailRegex.test(user.email)) {
        errors.email = " Please enter valid Email";
      }
    } else {
      errors.email = " Please enter Email";
    }
    if (user.isActiveOtp) {
      if (user.otp) {
        if (user.otp.length < 1 || user.otp.length > 6) {
          errors.otp = "Please enter valid Code";
        }
      } else {
        errors.otp = "Please enter Code";
      }
      if (user.password) {
        if (user.password.length < 8) {
          errors.password = "Please enter password with minimum 8 charecters";
        }
      } else {
        errors.password = "Please enter Password";
      }
      if (user.confirmPassword) {
        if (user.confirmPassword != user.password) {
          errors.confirmPassword = "Password not matched";
        }
      } else {
        errors.confirmPassword = "Please enter Confirm Password";
      }
    }
    return {
      errors,
      isValid: Object.keys(errors).length === 0,
    };
  };
  const onSubmit = () => {
    Keyboard.dismiss();
    setButtonLoading(true);
    setUser({ ...user, error: "" });
    if (isValid) {
      Auth.forgotPassword(user.email)
        .then((res) => {
          setButtonLoading(false);
          setUser({ ...user, isActiveOtp: true });
          Alert.alert("", "Confirm code has been sent to your email", [
            { text: "OK" },
          ]);
        })
        .catch((err) => {
          setButtonLoading(false);
          setUser({ ...user, error: "Email not registered" });
          console.log(err);
        });
    }
  };
  const onConfirmOtp = () => {
    if (isValid) {
      setLoading(true);
      Auth.forgotPasswordSubmit(user.email, user.otp, user.password)
        .then((data) => {
          onClear();
          setLoading(false);
          navigation.navigate("Sign In");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  const onClear = () => {
    setUser({
      email: "",
      password: "",
      otp: "",
      isActiveOtp: false,
    });
    setActive({
      email: false,
      password: false,
      otp: false,
    });
    setTouched({
      email: false,
      password: false,
      otp: false,
    });
  };
  const { errors, isValid } = validate();
  return loading ? (
    <View style={{ flex: 1 }}>
      <ActivityIndicator
        size="large"
        animating={loading}
        color={materialTheme.COLORS.BRAND}
        style={{ margin: 10, flex: 1, alignSelf: "center" }}
      />
    </View>
  ) : (
    <KeyboardAwareScrollView
      style={{ flex: 1 }}
      behavior="padding"
      enabled
      contentContainerStyle={{ flexGrow: 1 }}
      keyboardShouldPersistTaps="handled"
    >
      <View style={{ flex: 1 }}>
        <View style={styles.section}>
          <Image
            source={require("../../assets/images/logo.png")}
            style={styles.logo}
          />
          <Text style={styles.textStyle}>Change Password</Text>
          {!user.isActiveOtp ? (
            user.error ? (
              <Text
                style={[
                  styles.errorText,
                  { color: materialTheme.COLORS.ERROR },
                ]}
              >
                {user.error}
              </Text>
            ) : (
              <>
                <Input
                  bgColor="transparent"
                  placeholderTextColor={materialTheme.COLORS.MUTED}
                  color={materialTheme.COLORS.INPUT_TEXT}
                  type="email-address"
                  placeholder="Enter Your Registered Email"
                  autoCapitalize="none"
                  value={user.email}
                  style={[
                    styles.input,
                    active.email ? styles.inputActive : null,
                  ]}
                  onChangeText={(text) => handleChange("email", text)}
                  onBlur={() => onTouched("email")}
                  onFocus={() => toggleActive("email")}
                />
                {touched.email && errors.email && (
                  <Text
                    style={{
                      color: materialTheme.COLORS.ERROR,
                      fontSize: 12,
                    }}
                  >
                    {errors.email}
                  </Text>
                )}
              </>
            )
          ) : (
            <View style={{ justifyContent: "flex-end" }}>
              <TextInputMask
                type="custom"
                options={{
                  mask: "999999",
                }}
                bgColor="transparent"
                color={materialTheme.COLORS.INPUT_TEXT}
                placeholderTextColor={materialTheme.COLORS.MUTED}
                placeholder="Confirmation Code"
                autoCapitalize="none"
                value={user.otp}
                style={[
                  styles.maskinput,
                  active.otp ? styles.maskInputActive : null,
                ]}
                onChangeText={(text) => handleChange("otp", text)}
                onBlur={() => onTouched("otp")}
                onFocus={() => toggleActive("otp")}
              />
              {/* <Input
                bgColor="transparent"
                borderless
                color={materialTheme.COLORS.INPUT_TEXT}
                placeholderTextColor={materialTheme.COLORS.MUTED}
                placeholder="Confirmation Code"
                autoCapitalize="none"
                value={user.otp}
                style={[styles.input, active.otp ? styles.inputActive : null]}
                value={user.otp}
                onChangeText={(text) => handleChange("otp", text)}
                onBlur={() => toggleActive("otp")}
                onFocus={() => toggleActive("otp")}
              /> */}
              {errors.otp && touched.otp && (
                <Text
                  style={{ color: materialTheme.COLORS.ERROR, fontSize: 12 }}
                >
                  {errors.otp}
                </Text>
              )}
              <Input
                bgColor="transparent"
                color={materialTheme.COLORS.INPUT_TEXT}
                placeholderTextColor={materialTheme.COLORS.MUTED}
                password
                viewPass
                placeholder="New Password"
                iconColor={materialTheme.COLORS.PRIMARY}
                style={[
                  styles.input,
                  active.password ? styles.inputActive : null,
                ]}
                theme={{
                  colors: {
                    text: materialTheme.COLORS.INPUT_TEXT,
                    primary: materialTheme.COLORS.INPUT_TEXT,
                  },
                }}
                underlineColor="transparent"
                onChangeText={(text) => handleChange("password", text)}
                onBlur={() => onTouched("password")}
                onFocus={() => toggleActive("password")}
              />
              {touched.password && errors.password && (
                <Text
                  style={{ color: materialTheme.COLORS.ERROR, fontSize: 12 }}
                >
                  {errors.password}
                </Text>
              )}
              <Input
                bgColor="transparent"
                color={materialTheme.COLORS.INPUT_TEXT}
                placeholderTextColor={materialTheme.COLORS.MUTED}
                password
                viewPass
                placeholder="Confirm Password"
                iconColor={materialTheme.COLORS.PRIMARY}
                style={[
                  styles.input,
                  active.confirmPassword ? styles.inputActive : null,
                ]}
                theme={{
                  colors: {
                    text: materialTheme.COLORS.INPUT_TEXT,
                    primary: materialTheme.COLORS.INPUT_TEXT,
                  },
                }}
                underlineColor="transparent"
                onChangeText={(text) => handleChange("confirmPassword", text)}
                onBlur={() => onTouched("confirmPassword")}
                onFocus={() => toggleActive("confirmPassword")}
              />
              {touched.confirmPassword && errors.confirmPassword && (
                <Text
                  style={{ color: materialTheme.COLORS.ERROR, fontSize: 12 }}
                >
                  {errors.confirmPassword}
                </Text>
              )}
              <Text
                onPress={() => {
                  if (!errors.email) {
                    onSubmit();
                  } else {
                    Alert.alert("", "Please enter your email", [
                      { text: "OK" },
                    ]);
                  }
                }}
                style={{
                  alignSelf: "flex-end",
                  lineHeight: 12,
                  color: materialTheme.COLORS.CAPTION,
                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                Resend Code?
              </Text>
            </View>
          )}
          <View
            style={{
              marginTop: 20,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {buttonLoading ? (
              <ActivityIndicator
                size="small"
                color={materialTheme.COLORS.BRAND}
              />
            ) : (
              <Button
                style={{ height: 48, justifyContent: "center" }}
                color={materialTheme.COLORS.BUTTON_COLOR}
                onPress={() => {
                  if (!errors.email) {
                    user.isActiveOtp ? onConfirmOtp() : onSubmit();
                  } else {
                    Alert.alert("", "Please enter your email", [
                      { text: "OK" },
                    ]);
                  }
                }}
              >
                {user.isActiveOtp ? "Submit" : "Send Code"}
              </Button>
            )}
            <TouchableOpacity
              onPress={() => {
                onClear();
                navigation.navigate("Sign In");
              }}
            >
              <Text
                style={{
                  color: materialTheme.COLORS.CAPTION,
                  fontSize: 14,
                  marginTop: 40,
                }}
              >
                Do you want Sign In? Sign In
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <SafeAreaView style={{ backgroundColor: "white" }}>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderTopWidth: 0.5,
            width: width,
          }}
        >
          <Text style={styles.footer}>Meritore TeleCheck</Text>
        </View>
      </SafeAreaView>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  signup: {
    marginTop: Platform.OS === "android" ? -HeaderHeight : 0,
  },
  section: {
    flex: 1,
    justifyContent: "center",
    width: width >= 768 ? width * 0.5 : width,
    alignSelf: "center",
    paddingHorizontal: 15,
  },
  social: {
    width: 13 * 3.5,
    height: 13 * 3.5,
    borderRadius: 13 * 1.75,
    justifyContent: "center",
    shadowColor: "rgba(0, 0, 0, 0.3)",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 1,
  },
  input: {
    height: 50,
    borderRadius: 0,
    borderWidth: 0.25,
    marginTop: 10,
    backgroundColor: "transparent",
    borderColor: materialTheme.COLORS.DEFAULT,
  },
  maskinput: {
    height: 50,
    borderRadius: 0,
    borderWidth: 1,
    paddingLeft: 12,
    borderColor: materialTheme.COLORS.DEFAULT,
    fontSize: 16,
    // color: materialTheme.COLORS.INPUT_TEXT,
  },
  inputActive: {
    borderBottomColor: materialTheme.COLORS.ACTIVE,
  },
  maskInputActive: {
    borderBottomWidth: 2,
    borderBottomColor: materialTheme.COLORS.ACTIVE,
  },
  textStyle: {
    color: materialTheme.COLORS.PRIMARY,
    marginVertical: 20,
    fontSize: 24,
    fontWeight: "bold",
    alignSelf: "center",
  },
  logo: {
    marginBottom: 30,
    width: 300,
    height: 100,
    alignSelf: "center",
  },
  errorText: {
    color: materialTheme.COLORS.ERROR,
    textAlign: "center",
  },
  footer: {
    fontSize: 13,
    color: materialTheme.COLORS.CAPTION,
    fontWeight: "500",
    marginVertical: 5,
  },
});
export default ChangePassword;
