import React, { useState } from "react";
import {
  Alert,
  Dimensions,
  StyleSheet,
  Platform,
  ActivityIndicator,
  Image,
  SafeAreaView,
  TouchableOpacity,
  View,
} from "react-native";
import { addUser, fetchAwsUser, removeUnconfirmedUser } from "../api/users";
import { Card, Text, TextInput as Input, Surface } from "react-native-paper";

import { Auth } from "aws-amplify";
import Amplify from "@aws-amplify/core";
import config from "../../aws-exports";
import { materialTheme } from "../constants/";
import { HeaderHeight } from "../constants/utils";
import { Button } from "../ui/";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { TextInputMask } from "react-native-masked-text";

const { height, width } = Dimensions.get("window");
Amplify.configure(config);

const SignUp = (props) => {
  const { navigation } = props;
  const [loading, setLoading] = useState(false);
  const [singupLoading, setSignupLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    otp: "",
    isActiveOtp: false,
    awsUserId: "",
  });
  const [active, setActive] = useState({
    email: false,
    password: false,
    confirmPassword: false,
    otp: false,
  });
  const [touched, setTouched] = useState({
    email: false,
    password: false,
    confirmPassword: false,
    otp: false,
  });

  const handleChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };
  const toggleActive = (name) => {
    setActive({ ...active, [name]: true });
  };
  const onBlur = (name) => {
    setTouched({ ...touched, [name]: true });
  };
  const validate = () => {
    const errors = {};
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (user.email != "") {
      if (!emailRegex.test(user.email)) {
        errors.email = " Please enter valid Email";
      }
    } else {
      errors.email = " Please enter Email";
    }
    if (user.password) {
      if (user.password.length < 8) {
        errors.password = "Please enter password with minimum 8 charecters";
      }
    } else {
      errors.password = "Please enter Password";
    }
    if (user.confirmPassword) {
      if (user.confirmPassword !== user.password) {
        errors.confirmPassword = "Password not matched";
      }
    } else {
      errors.confirmPassword = "Please enter confirm password";
    }
    if (user.isActiveOtp) {
      if (user.otp) {
        if (user.otp.length < 6) {
          errors.otp = "Please enter valid Code";
        }
      } else {
        errors.otp = "Please enter Code";
      }
    }
    return {
      errors,
      isValid: Object.keys(errors).length === 0,
    };
  };
  const onBack = () => {
    setTouched({
      email: true,
      password: true,
      confirmPassword: true,
      otp: true,
    });
    setUser({
      ...user,
      isActiveOtp: false,
    });
  };

  const onSubmit = () => {
    setTouched({
      email: true,
      password: true,
      confirmPassword: true,
      otp: true,
    });
    if (isValid) {
      setSignupLoading(true);
      fetchAwsUser(user.email)
        .then((res) => {
          let isUserExist = res.data.getAwsUser.response;
          let status = res.data.getAwsUser.status;
          console.log(status);
          if (
            isUserExist.includes("User does not exist") ||
            isUserExist.includes("UserNotFoundException") ||
            status == 500
          ) {
            console.log("user not exist");
            userSignUp();
          } else {
            let UserData = JSON.parse(isUserExist);
            if (UserData.UserStatus == "CONFIRMED") {
              Alert.alert(
                `You are already registered please go through Login.`
              );
              onClear();
              setLoading(false);
              setSignupLoading(false);
            } else {
              removeUnconfirmedUser(user.email)
                .then((res) => {
                  console.log("user exist not confirmed");
                  userSignUp();
                })
                .catch((err) => {
                  console.log(err);
                  console.log("user not exist");
                  Alert.alert(`Something went wrong please try again.`);
                  onClear();
                  setLoading(false);
                  setSignupLoading(false);
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Alert.alert("Ooops..", `Please try again.`, "error");
          onClear();
          setLoader(false);
        });
    }
  };
  const userSignUp = () => {
    Auth.signUp({
      username: user.email,
      password: user.password,
      attributes: {
        email: user.email,
      },
      validationData: [],
    })
      .then((res) => {
        setUser({ ...user, isActiveOtp: true, awsUserId: res.userSub });
        setSignupLoading(false);
        Alert.alert("Confirmation code has been sent to your email.");
      })
      .catch((err) => {
        Alert.alert(err.message);
        console.log(err, "signup-error");
        setSignupLoading(false);
      });
  };

  const onConfirmOtp = () => {
    setLoading(true);
    Auth.confirmSignUp(user.email, user.otp, {
      forceAliasCreation: true,
    })
      .then((data) => {
        console.log("verified otp....");
        let userObj = {
          id: null,
          awsUserId: user.awsUserId,
          firstName: "",
          lastName: "",
          middleName: "",
          email: user.email,
          phone: "",
          role: "",
          active: true,
          facilities: [],
        };
        addUser(userObj)
          .then((res) => {
            console.log(res);
            navigation.navigate("Sign In");
            setLoading(false);
            onClear();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
        Alert.alert(err.message);
        console.log(err);
      });
  };

  const resendOtp = () => {
    Auth.resendSignUp(user.email)
      .then((res) => {
        // console.log("joshi.....", res)
        setUser({ ...user, isActiveOtp: true });
        Alert.alert("Confirmation code has been sent to your email.");
      })
      .catch((err) => console.log(err));
  };

  const onClear = () => {
    setUser({
      email: "",
      password: "",
      otp: "",
      confirmPassword: "",
      isActiveOtp: false,
    });
    setActive({
      email: false,
      password: false,
      confirmPassword: false,
      otp: false,
    });
    setTouched({
      email: false,
      password: false,
      confirmPassword: false,
      otp: false,
    });
  };

  const { errors, isValid } = validate();
  return loading ? (
    <View style={{ flex: 1 }}>
      <ActivityIndicator
        size="large"
        animating={loading}
        color={materialTheme.COLORS.BRAND}
        style={{ margin: 10, flex: 1, alignSelf: "center" }}
      />
    </View>
  ) : (
    <KeyboardAwareScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{ flexGrow: 1 }}
      behavior="padding"
      enabled
      keyboardShouldPersistTaps="handled"
    >
      <View style={{ flex: 1 }}>
        <View style={styles.section}>
          <Image
            source={require("../../assets/images/logo.png")}
            style={styles.logo}
          />
          <Text style={styles.textStyle}>Sign Up</Text>
          {!user.isActiveOtp ? (
            <View style={{ justifyContent: "flex-end", paddingHorizontal: 15 }}>
              <Input
                bgColor="transparent"
                color={materialTheme.COLORS.INPUT_TEXT}
                type="email-address"
                placeholder="Email"
                placeholderTextColor={materialTheme.COLORS.MUTED}
                autoCapitalize="none"
                theme={{
                  colors: {
                    text: materialTheme.COLORS.INPUT_TEXT,
                    primary: materialTheme.COLORS.INPUT_TEXT,
                  },
                }}
                underlineColor="transparent"
                style={[styles.input, active.email ? styles.inputActive : null]}
                onChangeText={(text) => handleChange("email", text)}
                onBlur={() => onBlur("email")}
                onFocus={() => toggleActive("email")}
                value={user.email}
              />
              {touched.email && errors.email && (
                <Text
                  style={{
                    color: materialTheme.COLORS.ERROR,
                    fontSize: 12,
                  }}
                >
                  {errors.email}
                </Text>
              )}
              <Input
                bgColor="transparent"
                placeholderTextColor={materialTheme.COLORS.MUTED}
                color={materialTheme.COLORS.ACTIVE}
                placeholder="Password"
                secureTextEntry={true}
                iconColor={materialTheme.COLORS.PRIMARY}
                style={[
                  styles.input,
                  active.password ? styles.inputActive : null,
                ]}
                theme={{
                  colors: {
                    text: materialTheme.COLORS.INPUT_TEXT,
                    primary: materialTheme.COLORS.INPUT_TEXT,
                  },
                }}
                underlineColor="transparent"
                onChangeText={(text) => handleChange("password", text)}
                onBlur={() => onBlur("password")}
                onFocus={() => toggleActive("password")}
                value={user.password}
              />
              {touched.password && errors.password && (
                <Text
                  style={{
                    color: materialTheme.COLORS.ERROR,
                    fontSize: 12,
                  }}
                >
                  {errors.password}
                </Text>
              )}
              <Input
                bgColor="transparent"
                placeholderTextColor={materialTheme.COLORS.MUTED}
                color={materialTheme.COLORS.ACTIVE}
                secureTextEntry={true}
                placeholder="Confirm Password"
                iconColor={materialTheme.COLORS.PRIMARY}
                style={[
                  styles.input,
                  active.confirmPassword ? styles.inputActive : null,
                ]}
                theme={{
                  colors: {
                    text: materialTheme.COLORS.INPUT_TEXT,
                    primary: materialTheme.COLORS.INPUT_TEXT,
                  },
                }}
                underlineColor="transparent"
                onChangeText={(text) => handleChange("confirmPassword", text)}
                onBlur={() => onBlur("confirmPassword")}
                onFocus={() => toggleActive("confirmPassword")}
                value={user.confirmPassword}
              />
              {touched.confirmPassword && errors.confirmPassword && (
                <Text
                  style={{
                    color: materialTheme.COLORS.ERROR,
                    fontSize: 12,
                  }}
                >
                  {errors.confirmPassword}
                </Text>
              )}
              {singupLoading ? (
                <ActivityIndicator
                  size="small"
                  color={materialTheme.COLORS.BRAND}
                />
              ) : (
                <Button
                  style={{
                    height: 48,
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                  color={materialTheme.COLORS.BUTTON_COLOR}
                  onPress={() => onSubmit()}
                >
                  Sign Up
                </Button>
              )}
              <TouchableOpacity
                onPress={() => {
                  onClear();
                  navigation.navigate("Sign In");
                }}
              >
                <Text
                  style={{
                    color: materialTheme.COLORS.CAPTION,
                    fontSize: 14,
                    marginTop: 40,
                    alignSelf: "center",
                  }}
                >
                  Already have an account? Sign In
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={{ justifyContent: "flex-end", paddingHorizontal: 15 }}>
              <TextInputMask
                type="custom"
                options={{
                  mask: "999999",
                }}
                bgColor="transparent"
                placeholderTextColor={materialTheme.COLORS.MUTED}
                color={materialTheme.COLORS.ACTIVE}
                placeholder="Enter Confirmation Code"
                autoCapitalize="none"
                value={user.otp}
                style={[
                  styles.maskinput,
                  active.otp ? styles.maskInputActive : null,
                ]}
                onChangeText={(text) => handleChange("otp", text)}
                onBlur={() => onBlur("otp")}
                onFocus={() => toggleActive("otp")}
              />
              {/* <Input
                  bgColor="transparent"
                  placeholderTextColor={materialTheme.COLORS.MUTED}
                  color={materialTheme.COLORS.ACTIVE}
                  placeholder="Enter Confirmation Code"
                  autoCapitalize="none"
                  value={user.otp}
                  style={[styles.input, active.otp ? styles.inputActive : null]}
                  onChangeText={(text) => handleChange("otp", text)}
                  onBlur={() => onBlur("otp")}
                  onFocus={() => toggleActive("otp")}
                /> */}
              {touched.otp && errors.otp && (
                <Text
                  style={{
                    color: materialTheme.COLORS.ERROR,
                    fontSize: 12,
                  }}
                >
                  {errors.otp}
                </Text>
              )}
              <Text
                style={{
                  alignSelf: "flex-end",
                  lineHeight: 12,
                  color: materialTheme.COLORS.CAPTION,
                  fontSize: 12,
                  marginTop: 10,
                }}
                onPress={() => resendOtp()}
              >
                Resend Code?
              </Text>
              <Button
                style={{ height: 48, justifyContent: "center", marginTop: 20 }}
                color={materialTheme.COLORS.BUTTON_COLOR}
                onPress={() => user.otp != "" && !errors.otp && onConfirmOtp()}
              >
                Submit
              </Button>
              <TouchableOpacity
                style={{ width: "auto", marginTop: 40 }}
                onPress={() => {
                  onBack();
                }}
              >
                <Text
                  style={{
                    color: materialTheme.COLORS.CAPTION,
                    fontSize: 12,
                    textAlign: "center",
                  }}
                >
                  {"<< "}Back
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
      <SafeAreaView style={{ backgroundColor: "white" }}>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderTopWidth: 0.5,
            width: width,
          }}
        >
          <Text style={styles.footer}>Meritore TeleCheck</Text>
        </View>
      </SafeAreaView>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  signup: {
    marginTop: Platform.OS === "android" ? -HeaderHeight : 0,
  },
  social: {
    width: 13 * 3.5,
    height: 13 * 3.5,
    borderRadius: 13 * 1.75,
    justifyContent: "center",
    shadowColor: "rgba(0, 0, 0, 0.3)",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 1,
  },
  section: {
    flex: 1,
    justifyContent: "center",
    width: width >= 768 ? width * 0.5 : width,
    alignSelf: "center",
  },
  input: {
    height: 50,
    borderRadius: 0,
    borderWidth: 0.25,
    marginTop: 10,
    backgroundColor: "transparent",
    borderColor: materialTheme.COLORS.DEFAULT,
  },
  maskinput: {
    height: 50,
    borderRadius: 0,
    borderWidth: 1,
    paddingLeft: 12,
    borderColor: materialTheme.COLORS.DEFAULT,
    fontSize: 16,
    // color: materialTheme.COLORS.INPUT_TEXT,
  },
  inputActive: {
    borderBottomColor: materialTheme.COLORS.ACTIVE,
  },
  maskInputActive: {
    borderBottomWidth: 2,
    borderBottomColor: materialTheme.COLORS.ACTIVE,
  },
  textStyle: {
    color: materialTheme.COLORS.PRIMARY,
    marginVertical: 20,
    fontSize: 24,
    fontWeight: "bold",
    alignSelf: "center",
  },
  logo: {
    marginBottom: 30,
    width: 300,
    height: 100,
    alignSelf: "center",
  },
  footer: {
    fontSize: 13,
    color: materialTheme.COLORS.CAPTION,
    fontWeight: "500",
    marginVertical: 5,
  },
});
export default SignUp;
