import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  StatusBar,
  Dimensions,
  ActivityIndicator,
  Image,
  View,
} from "react-native";
import { Text } from "react-native-paper";
const { height, width } = Dimensions.get("screen");
import { Button } from "../ui/";
import materialTheme from "../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Onboarding = (props) => {
  const { navigation } = props;
  const [emrData, setEmrData] = useState(undefined);

  useEffect(() => {
    async function getData() {
      const data = await AsyncStorage.getItem("EmrApptIds");
      if (data) {
        console.log(data, "chached data...");
        let parsedData = JSON.parse(data);
        setEmrData(parsedData);
      } else {
        setEmrData(undefined);
      }
    }
    getData();
  }, []);

  const onGetStarted = async () => {
    if (props.isLoggedIn && emrData) {
      navigation.navigate("App");
    } else if (props.isLoggedIn === false && emrData) {
      navigation.navigate("App");
    } else {
      alert("Only invited user can access the app");
      // navigation.navigate("App");
    }
  };

  return (
    <View style={styles.container}>
      <StatusBar barStyle="dark-content" />
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Image
          source={require("../../assets/images/icon.png")}
          style={styles.logo}
          resizeMode="contain"
        />
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 34,
            color: materialTheme.COLORS.BRAND2,
          }}
        >
          MERIT
          <Text
            style={{
              color: materialTheme.COLORS.BRAND,
              fontWeight: "bold",
              fontSize: 34,
            }}
          >
            CARE
          </Text>
        </Text>
        <Text style={{ fontSize: 12, color: materialTheme.COLORS.MUTED }}>
          Partner in care of your health.
        </Text>
      </View>
      <View style={styles.padded}>
        <Button
          style={styles.button}
          color={materialTheme.COLORS.BUTTON_COLOR}
          onPress={() => onGetStarted()}
        >
          GET STARTED
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: materialTheme.COLORS.BACK,
  },
  padded: {
    position: "relative",
    justifyContent: "center",
    alignSelf: "center",
    width: width * 0.8,
    bottom: 13,
  },
  button: {
    height: 45,
    shadowRadius: 0,
    justifyContent: "center",
    shadowOpacity: 0,
  },
  pro: {
    backgroundColor: materialTheme.COLORS.LABEL,
    paddingHorizontal: 8,
    marginLeft: 12,
    borderRadius: 2,
    height: 22,
  },
  logo: {
    aspectRatio: 16 / 9,
    width: "100%",
    height: height * 0.25,
  },
  surface: {
    marginTop: 15,
  },
});
export default Onboarding;
