import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Alert,
  Dimensions,
  Platform,
  View,
} from "react-native";
import { Card, Text } from "react-native-paper";
import { API, graphqlOperation } from "aws-amplify";
import Icon from "./Icon";
import materialTheme from "../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { Auth } from "aws-amplify";
import Amplify from "@aws-amplify/core";
import config from "../../aws-exports";
import { getAthenaApi } from "../graphql/queries";
const { width } = Dimensions.get("window");

Amplify.configure(config);

const DrawerItem = (props) => {
  const { title, focused, navigation } = props;

  const renderIcon = () => {
    switch (title) {
      case "Dashboard":
        return (
          <Icon
            size={15}
            name={require("../../assets/icons/dashboard.png")}
            color={focused ? "white" : materialTheme.COLORS.MUTED}
          />
        );
      case "My Health":
        return (
          <Icon
            size={15}
            name="hospital"
            color={focused ? "white" : materialTheme.COLORS.MUTED}
          />
        );
      case "Inbox":
        return (
          <Icon
            size={15}
            name="chat"
            color={focused ? "white" : materialTheme.COLORS.MUTED}
          />
        );
      case "My Info":
        return (
          <Icon
            size={15}
            name="information"
            color={focused ? "white" : materialTheme.COLORS.MUTED}
          />
        );
      case "Appointments":
        return (
          <Icon
            size={15}
            name="grid"
            color={focused ? "white" : materialTheme.COLORS.MUTED}
          />
        );
      case "Settings":
        return (
          <Icon
            size={15}
            name={require("../../assets/icons/settings.png")}
            color={focused ? "white" : materialTheme.COLORS.MUTED}
          />
        );
      case "Payments":
        return (
          <Icon
            size={17}
            name={require("../../assets/icons/payment.png")}
            color={focused ? "white" : materialTheme.COLORS.MUTED}
          />
        );
      case "Logout":
        return (
          <Icon
            size={15}
            name="logout"
            color={focused ? "white" : materialTheme.COLORS.MUTED}
          />
        );
      case "Sign Up":
        return (
          <Icon
            size={15}
            name="md-person-add"
            family="ionicon"
            color={focused ? "white" : materialTheme.COLORS.MUTED}
          />
        );
      default:
        return null;
    }
  };
  const goToLogin = () => {
    Auth.signOut()
      .then(async () => {
        await AsyncStorage.clear();
        props.navigation.navigate("Auth", { screen: "Sign In" });
      })
      .catch((err) => console.log("logout error..", err));
  };

  const onSignOut = () => {
    if (Platform.OS == "web") {
      confirm("Are you sure want to log out?") && goToLogin();
    } else {
      Alert.alert("", "Are you sure want to log out?", [
        { text: "CANCEL" },
        { text: "OK", onPress: () => goToLogin() },
      ]);
    }
  };
  return (
    <View style={{ paddingVertical: 5 }}>
      <TouchableOpacity
        style={[
          styles.defaultStyle,
          focused ? [styles.activeStyle, styles.shadow] : null,
        ]}
        onPress={() => {
          if (title == "Logout") {
            onSignOut();
          } else if (title == "My Info") {
            navigation.navigate("MyInfo");
          } else navigation.navigate(title);
        }}
      >
        {renderIcon()}
        <Text
          size={15}
          style={{ marginLeft: 10, color: focused ? "white" : "black" }}
        >
          {title}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default DrawerItem;

const styles = StyleSheet.create({
  defaultStyle: {
    flex: 1,
    flexDirection: "row",
    paddingHorizontal: 16,
    alignItems: "center",
    height: 55,
  },
  activeStyle: {
    backgroundColor: materialTheme.COLORS.ACTIVE,
    borderRadius: 4,
  },
  shadow: {
    shadowColor: materialTheme.COLORS.BLOCK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 8,
    shadowOpacity: 0.2,
  },
  pendingBadge: {
    height: 23,
    width: 23,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-end",
    backgroundColor: "red",
    borderRadius: 40,
    marginLeft: 15,
  },
});
