import React, { useState } from "react";
import { Dimensions, TouchableOpacity, SafeAreaView, View } from "react-native";
import { Card, TextInput as Input, Text, Menu } from "react-native-paper";
import { materialTheme } from "../constants/";
import { TextInputMask } from "react-native-masked-text";
import data from "../../data.json";
const { height, width } = Dimensions.get("window");

const PersonalInfoForm1 = (props) => {
  const { styles, errors, touched } = props;
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  return (
    <View style={{ flex: 1 }}>
      <Text style={styles.label}>First Name:*</Text>
      <Input
        placeholder="First Name"
        name="firstName"
        color={materialTheme.COLORS.INPUT_TEXT}
        type="default"
        autoCapitalize="none"
        bgColor="transparent"
        placeholderTextColor={"gray"}
        style={[
          styles.input,
          props.active.firstName ? styles.inputActive : null,
        ]}
        theme={{
          colors: {
            text: materialTheme.COLORS.INPUT_TEXT,
            primary: materialTheme.COLORS.INPUT_TEXT,
          },
        }}
        underlineColor="transparent"
        onFocus={() => props.toggleActive("firstName")}
        onBlur={() => props.onBlur("firstName")}
        onChangeText={(text) => props.handleChange(text, "firstName")}
        value={props.personalInfo.firstName}
      />
      {errors.firstName && touched.firstName && (
        <Text style={styles.errorText}>{errors.firstName}</Text>
      )}
      <Text style={styles.label}>Last Name:*</Text>
      <Input
        placeholder="Last Name"
        name="lastName"
        color={materialTheme.COLORS.INPUT_TEXT}
        type="default"
        autoCapitalize="none"
        bgColor="transparent"
        placeholderTextColor={"gray"}
        style={[
          styles.input,
          props.active.lastName ? styles.inputActive : null,
        ]}
        theme={{
          colors: {
            text: materialTheme.COLORS.INPUT_TEXT,
            primary: materialTheme.COLORS.INPUT_TEXT,
          },
        }}
        underlineColor="transparent"
        onFocus={() => props.toggleActive("lastName")}
        onBlur={() => props.onBlur("lastName")}
        onChangeText={(text) => props.handleChange(text, "lastName")}
        value={props.personalInfo.lastName}
      />
      {errors.lastName && touched.lastName && (
        <Text style={styles.errorText}>{errors.lastName}</Text>
      )}
      <Text style={styles.label}>SSN:*</Text>
      <Input
        type="default"
        options={{
          mask: "999-99-9999",
        }}
        placeholder="SSN"
        name="socialSecurityNumber"
        autoCapitalize="none"
        bgColor="transparent"
        placeholderTextColor={"gray"}
        style={[
          styles.maskinput,
          props.active.socialSecurityNumber ? styles.maskInputActive : null,
        ]}
        onFocus={() => props.toggleActive("socialSecurityNumber")}
        onBlur={() => props.onBlur("socialSecurityNumber")}
        onChangeText={(text) =>
          props.handleChange(text, "socialSecurityNumber")
        }
        value={props.personalInfo.socialSecurityNumber}
      />
      {errors.socialSecurityNumber && touched.socialSecurityNumber && (
        <Text style={styles.errorText}>{errors.socialSecurityNumber}</Text>
      )}
      <Text style={styles.label}>Address:*</Text>
      <Input
        placeholder="Address"
        name="address"
        color={materialTheme.COLORS.INPUT_TEXT}
        type="default"
        autoCapitalize="none"
        bgColor="transparent"
        style={[styles.input, props.active.address ? styles.inputActive : null]}
        theme={{
          colors: {
            text: materialTheme.COLORS.INPUT_TEXT,
            primary: materialTheme.COLORS.INPUT_TEXT,
          },
        }}
        underlineColor="transparent"
        onFocus={() => props.toggleActive("address")}
        onBlur={() => props.onBlur("address")}
        onChangeText={(text) => props.handleChange(text, "address")}
        value={props.personalInfo.address}
      />
      {errors.address && touched.address && (
        <Text style={styles.errorText}>{errors.address}</Text>
      )}
      <Text style={styles.label}>City & Zip Code:*</Text>
      <View style={[styles.cityandzip, { paddingHorizontal: 0 }]}>
        <View style={{ paddingHorizontal: 0, marginRight: 10, flex: 1 }}>
          <Input
            placeholder="City"
            name="city"
            color={materialTheme.COLORS.INPUT_TEXT}
            type="default"
            autoCapitalize="none"
            bgColor="transparent"
            style={[
              styles.input2,
              props.active.city ? styles.inputActive : null,
            ]}
            theme={{
              colors: {
                text: materialTheme.COLORS.INPUT_TEXT,
                primary: materialTheme.COLORS.INPUT_TEXT,
              },
            }}
            underlineColor="transparent"
            onFocus={() => props.toggleActive("city")}
            onBlur={() => props.onBlur("city")}
            onChangeText={(text) => props.handleChange(text, "city")}
            value={props.personalInfo.city}
          />
          {errors.city && touched.city && (
            <Text style={[styles.errorText, { flexWrap: "wrap" }]}>
              {errors.city}
            </Text>
          )}
        </View>
        <View style={{ flex: 0.5, paddingHorizontal: 0 }}>
          <TextInputMask
            type="zip-code"
            options={{
              mask:
                props.personalInfo.zipCode.length > 5 ? "99999-999" : "99999",
            }}
            placeholder="Zip Code"
            name="zipCode"
            autoCapitalize="none"
            bgColor="transparent"
            placeholderTextColor={"gray"}
            style={[
              styles.maskinput,
              props.active.zipCode ? styles.maskInputActive : null,
            ]}
            onBlur={() => props.onBlur("zipCode")}
            onFocus={() => props.toggleActive("zipCode")}
            onChangeText={(text) => props.handleChange(text, "zipCode")}
            value={props.personalInfo.zipCode}
          />
          {errors.zipCode && touched.zipCode && (
            <Text
              style={[
                styles.errorText,
                { flexWrap: "wrap", width: width * 0.3 },
              ]}
            >
              {errors.zipCode}
            </Text>
          )}
        </View>
      </View>
      <Text style={styles.label}>State:*</Text>
      <Menu
        visible={open1}
        onDismiss={() => setOpen1(false)}
        contentStyle={{ marginTop: 60 }}
        anchor={
          <TouchableOpacity
            style={[
              styles.input2,
              {
                backgroundColor: "transparent",
                justifyContent: "center",
                paddingLeft: 10,
              },
            ]}
            onPress={() => setOpen1(true)}
          >
            <Text
              style={{
                marginHorizontal: 5,
                color: materialTheme.COLORS.INPUT_TEXT,
              }}
            >
              {props.personalInfo.state
                ? props.personalInfo.state
                : "Select State"}
            </Text>
          </TouchableOpacity>
        }
      >
        {data.states.map((item) => {
          return (
            <Menu.Item
              key={item.abbreviation}
              onPress={() => {
                setOpen1(false);
                props.handleChange(item.abbreviation, "state");
              }}
              title={item.name}
            />
          );
        })}
      </Menu>

      {errors.state && touched.state && (
        <Text style={styles.errorText}>{errors.state}</Text>
      )}
      <Text style={styles.label}>Country:</Text>
      <Menu
        visible={open2}
        onDismiss={() => setOpen2(false)}
        anchor={
          <TouchableOpacity
            style={[
              styles.input2,
              {
                backgroundColor: "transparent",
                justifyContent: "center",
                paddingLeft: 10,
              },
            ]}
            onPress={() => setOpen2(true)}
          >
            <Text
              style={{
                marginHorizontal: 5,
                color: materialTheme.COLORS.INPUT_TEXT,
              }}
            >
              {props.personalInfo.country
                ? props.personalInfo.country
                : "Select Country"}
            </Text>
          </TouchableOpacity>
        }
      >
        {data.countries.map((item) => {
          return (
            <Menu.Item
              key={item.abbreviation}
              onPress={() => {
                setOpen2(false);
                props.handleChange(item.abbreviation, "country");
              }}
              title={item.name}
            />
          );
        })}
      </Menu>
      <Text
        style={{
          marginHorizontal: 5,
          marginVertical: 15,
          color: materialTheme.COLORS.ERROR,
        }}
      >
        ** Please Update the changes before moving to next screen. **
      </Text>
    </View>
  );
};
export default PersonalInfoForm1;
