import React, { useState } from "react";
import { Card, TextInput as Input, Text, Menu } from "react-native-paper";
import { materialTheme } from "../constants/";
import { TextInputMask } from "react-native-masked-text";
import data from "../../data.json";
import { TouchableOpacity, View } from "react-native";

const PersonalInfoForm5 = (props) => {
  const [open1, setOpen1] = useState(false);
  const { styles, errors, touched } = props;
  return (
    <View style={{ flex: 1 }}>
      <Text style={styles.label}>Driver's License Number:</Text>
      <Input
        placeholder="Drivers License Number"
        color={materialTheme.COLORS.INPUT_TEXT}
        type="default"
        name="driversLicenseNumber"
        autoCapitalize="none"
        bgColor="transparent"
        placeholderTextColor={"gray"}
        style={[
          styles.input,
          props.active.driversLicenseNumber ? styles.inputActive : null,
        ]}
        theme={{
          colors: {
            text: materialTheme.COLORS.INPUT_TEXT,
            primary: materialTheme.COLORS.INPUT_TEXT,
          },
        }}
        underlineColor="transparent"
        onFocus={() => props.toggleActive("driversLicenseNumber")}
        onChangeText={(text) =>
          props.handleChange(text, "driversLicenseNumber")
        }
        value={props.personalInfo.driversLicenseNumber}
      />

      {errors.driversLicenseNumber && touched.driversLicenseNumber && (
        <Text style={styles.errorText}>{errors.driversLicenseNumber}</Text>
      )}

      <Text style={styles.label}>Driver's License State:*</Text>
      <Menu
        visible={open1}
        onDismiss={() => setOpen1(false)}
        contentStyle={{ marginTop: 60 }}
        anchor={
          <TouchableOpacity
            style={[
              styles.input2,
              {
                backgroundColor: "transparent",
                justifyContent: "center",
                paddingLeft: 10,
              },
            ]}
            onPress={() => setOpen1(true)}
          >
            <Text
              style={{
                marginHorizontal: 5,
                color: materialTheme.COLORS.INPUT_TEXT,
              }}
            >
              {props.personalInfo.driverLienseState
                ? props.personalInfo.driverLienseState
                : "Select State"}
            </Text>
          </TouchableOpacity>
        }
      >
        {data.states.map((item) => {
          return (
            <Menu.Item
              key={item.abbreviation}
              onPress={() => {
                setOpen1(false);
                props.handleChange(item.abbreviation, "driverLienseState");
              }}
              title={item.name}
            />
          );
        })}
      </Menu>

      {errors.driverLienseState && touched.driverLienseState && (
        <Text style={styles.errorText}>{errors.driverLienseState}</Text>
      )}

      <Text style={styles.label}>License Expiry Date :*</Text>
      <TextInputMask
        type="datetime"
        options={{
          format: "MM/DD/YYYY",
        }}
        placeholder="MM/DD/YYYY"
        name="driverLicenseExpirationDate"
        autoCapitalize="none"
        bgColor="transparent"
        placeholderTextColor={"gray"}
        style={[
          styles.maskinput,
          props.active.driverslicenseexpirationdate
            ? styles.maskInputActive
            : null,
        ]}
        theme={{
          colors: {
            text: materialTheme.COLORS.INPUT_TEXT,
            primary: materialTheme.COLORS.INPUT_TEXT,
          },
        }}
        underlineColor="transparent"
        onBlur={() => props.onBlur("driverLicenseExpirationDate")}
        onFocus={() => props.toggleActive("driverLicenseExpirationDate")}
        onChangeText={(text) =>
          props.handleChange(text, "driverLicenseExpirationDate")
        }
        value={props.personalInfo.driverLicenseExpirationDate}
      />
      {errors.driverLicenseExpirationDate &&
        touched.driverLicenseExpirationDate && (
          <Text style={styles.errorText}>
            {errors.driverLicenseExpirationDate}
          </Text>
        )}
      <Text
        style={{
          marginHorizontal: 5,
          marginVertical: 15,
          color: materialTheme.COLORS.ERROR,
        }}
      >
        ** Please Update the changes before moving to next screen. **
      </Text>
    </View>
  );
};
export default PersonalInfoForm5;
