import { API, graphqlOperation } from "aws-amplify";
import config from "../../aws-exports";
import { listFacilitys, getFacility } from "../graphql/queries";
import { createFacility, updateFacility } from "../graphql/mutations";

API.configure(config);

export const addFacility = async (facility) => {
  return await API.graphql(
    graphqlOperation(createFacility, {
      input: facility,
    })
  );
};
export const fetchFacility = async (id) => {
  return await API.graphql(
    graphqlOperation(getFacility, {
      id: id,
    })
  );
};
export const allFacilities = async (id) => {
  return await API.graphql(graphqlOperation(listFacilitys, { limit: 1000 }));
};
