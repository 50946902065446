import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Dimensions,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { Card, Text } from "react-native-paper";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { materialTheme } from "../constants";
import { useIsFocused } from "@react-navigation/native";
import MyTasksScreen from "../screens/MyTasksScreen";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { API, graphqlOperation } from "aws-amplify";
import config from "../../aws-exports";
import { Button } from "../ui";

import { getAthenaApi } from "../graphql/queries";
import moment from "moment";
import { updateAthenaApi, putAthenaApi } from "../graphql/mutations";

API.configure(config);

const { height, width } = Dimensions.get("window");

const AppointmentDetail = (props) => {
  const { navigation } = props;
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState(false);
  const isFocused = useIsFocused();

  const [appointmentType, setAppointmentType] = useState("past");
  const [appointment, setAppointment] = useState({});
  const [provider, setProvider] = useState({});
  const [status, setStatus] = useState("");

  const [emr, setEmr] = useState(null);

  useEffect(() => {
    getAppointment(props.route.params.appointment.appointmentid);
    setAppointmentType(props.route.params.appointmentType);
    fetchProvider(props.route.params.appointment.providerid);
  }, [props.route.params.appointment]);

  const onTaskCompleted = () => {
    setTasks(true);
  };

  useEffect(() => {
    async function getData() {
      let emrdata = await AsyncStorage.getItem("EmrApptIds");
      let parsedData = JSON.parse(emrdata);
      setEmr(parsedData);
    }
  }, []);

  const getAppointment = async (id) => {
    setLoading(true);
    let token = await AsyncStorage.getItem("Token");
    let emrdata = await AsyncStorage.getItem("EmrApptIds");
    let parsedData = JSON.parse(emrdata);
    let practiceId = parsedData.fId;
    await API.graphql(
      graphqlOperation(getAthenaApi, {
        apiUrl: `https://api.platform.athenahealth.com/v1/${practiceId}/appointments/${id}`,
        token: token,
      })
    )
      .then((res) => {
        let response = res.data.getAthenaApi.response;
        let data = JSON.parse(response);
        if (data.error) {
          alert(data.error);
        } else {
          setAppointment(data[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error while fetching appointment", err);
        setLoading(false);
      });
  };

  const fetchProvider = async (id) => {
    setLoading(true);
    let token = await AsyncStorage.getItem("Token");
    let emrdata = await AsyncStorage.getItem("EmrApptIds");
    let parsedData = JSON.parse(emrdata);
    let practiceId = parsedData.fId;
    await API.graphql(
      graphqlOperation(getAthenaApi, {
        apiUrl: `https://api.platform.athenahealth.com/v1/${practiceId}/providers/${id}`,
        token: token,
      })
    )
      .then((res) => {
        let response = res.data.getAthenaApi.response;
        let data = JSON.parse(response);
        if (data.error) {
          alert(data.error);
        } else {
          setProvider(data[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error while fetching provider", err);
        setLoading(false);
      });
  };

  const refresh = async (id) => {
    setLoading(true);
    let appointmentid = id;
    let token = await AsyncStorage.getItem("Token");
    let emrdata = await AsyncStorage.getItem("EmrApptIds");
    let parsedData = JSON.parse(emrdata);
    let practiceId = parsedData.fId;
    await API.graphql(
      graphqlOperation(getAthenaApi, {
        apiUrl: `https://api.platform.athenahealth.com/v1/${practiceId}/appointments/${appointmentid}`,
        token: token,
      })
    )
      .then((res) => {
        let response = res.data.getAthenaApi.response;
        let data = JSON.parse(response);
        if (data.error) {
          alert(data.error);
        } else {
          setAppointment(data[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error while fetching Appointment", err);
        setLoading(false);
      });
  };

  const checkout = async (id) => {
    setLoading(true);
    let token = await AsyncStorage.getItem("Token");
    let emrdata = await AsyncStorage.getItem("EmrApptIds");
    let parsedData = JSON.parse(emrdata);
    let practiceId = parsedData.fId;
    await API.graphql(
      graphqlOperation(updateAthenaApi, {
        apiUrl: `https://api.platform.athenahealth.com/v1/${practiceId}/appointments/${id}/checkout`,
        token: token,
      })
    )
      .then((res) => {
        let data = res.data.updateAthenaApi.response;
        let response = JSON.parse(data);
        if (response.success) {
          alert("Appointment Check-Out Successfull");
          refresh(id);
        } else {
          alert(response.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error while checkin", err);
        setLoading(false);
      });
  };

  const checkin = async (id) => {
    setLoading(true);
    let token = await AsyncStorage.getItem("Token");
    let emrdata = await AsyncStorage.getItem("EmrApptIds");
    let parsedData = JSON.parse(emrdata);
    let practiceId = parsedData.fId;
    await API.graphql(
      graphqlOperation(updateAthenaApi, {
        apiUrl: `https://api.platform.athenahealth.com/v1/${practiceId}/appointments/${id}/checkin`,
        token: token,
      })
    )
      .then((res) => {
        let data = res.data.updateAthenaApi.response;
        let response = JSON.parse(data);
        if (response.success) {
          alert("Appointment Check-in Successfull");
          refresh(id);
        } else {
          alert(response.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error while checkin", err);
        setLoading(false);
      });
  };

  const cancel = async (id) => {
    setLoading(true);
    let token = await AsyncStorage.getItem("Token");
    let emrdata = await AsyncStorage.getItem("EmrApptIds");
    let parsedData = JSON.parse(emrdata);
    let practiceId = parsedData.fId;
    let patientId = parsedData.pId;
    await API.graphql(
      graphqlOperation(putAthenaApi, {
        apiUrl: `https://api.platform.athenahealth.com/v1/${practiceId}/appointments/${id}/cancel`,
        body: `patientid=${patientId}`,
        token: token,
      })
    )
      .then((res) => {
        let data = res.data.putAthenaApi.response;
        let response = JSON.parse(data);
        if (response.status === "x") {
          alert("Appointment Cancelled");
          props.navigation.goBack();
        } else {
          alert(response.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error while checkin", err);
        setLoading(false);
      });
  };

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        paddingHorizontal: 5,
      }}
    >
      <ScrollView>
        <View style={{ flex: 1 }}>
          <View showsVerticalScrollIndicator={true}>
            <View style={{ flex: 1, paddingVertical: 20 }}>
              {loading ? (
                <ActivityIndicator
                  size="large"
                  color={materialTheme.COLORS.CAPTION}
                  style={{ margin: 10 }}
                />
              ) : (
                <View>
                  <View style={styles.appointment}>
                    <Text style={styles.time}>
                      {moment(appointment?.date).format("DD MMMM YYYY")}
                    </Text>
                    <Text style={styles.details}>Appointment Details:</Text>
                    <Text style={styles.answers}>
                      Reason :
                      {appointment?.patientappointmenttypename
                        ? "  " + appointment.patientappointmenttypename
                        : "--"}
                    </Text>
                    <Text style={styles.answers}>
                      Start Time :
                      {appointment?.starttime
                        ? "  " + appointment.starttime
                        : "--"}
                    </Text>
                    <Text style={styles.answers}>
                      Duration :
                      {appointment?.duration
                        ? "  " + appointment.duration
                        : "--"}
                    </Text>
                    <Text style={styles.answers}>
                      Status :
                      {appointment?.appointmentstatus === "f" &&
                      appointmentType === "future"
                        ? " Future Appointment "
                        : appointment?.appointmentstatus === "2"
                        ? " Checked in "
                        : appointment?.appointmentstatus === "3"
                        ? " Checked out "
                        : appointment?.appointmentstatus === "x"
                        ? " cancelled "
                        : "Past Appointment"}
                    </Text>
                    <Text style={styles.details}>Provider Details:</Text>
                    <Text style={styles.answers}>
                      Provider Name :
                      {provider ? "  " + provider.displayname : "--"}
                    </Text>
                    <Text style={styles.answers}>
                      Provider Type :
                      {provider ? "  " + provider.providertype : "--"}
                    </Text>
                    <Text style={styles.answers}>
                      Provider Speciality :
                      {provider ? "  " + provider.specialty : "--"}
                    </Text>
                    <Text style={styles.answers}>
                      Billable :{provider ? "  " + provider.billable : "--"}
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      paddingHorizontal: 5,
                    }}
                  >
                    {appointment?.appointmentstatus ===
                    "2" ? undefined : appointment?.appointmentstatus === "f" &&
                      appointmentType === "future" ? (
                      <View>
                        <Text style={styles.notes}>
                          Complete tasks below before your appointment date to
                          reduce your waiting time
                        </Text>

                        <MyTasksScreen
                          appointment={appointment}
                          departmentId={appointment.departmentid}
                          onTaskCompleted={onTaskCompleted}
                          {...props}
                        />

                        <Button
                          onPress={() => checkin(appointment?.appointmentid)}
                          style={tasks ? styles.button : styles.disabledButton}
                          disabled={tasks ? false : true}
                        >
                          Check In
                        </Button>
                      </View>
                    ) : undefined}
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  appointment: {
    width: width - 20,
    backgroundColor: materialTheme.COLORS.BRAND,
    borderRadius: 10,
    justifyContent: "center",
    paddingVertical: 10,
    alignSelf: "center",
  },
  time: {
    fontSize: 20,
    fontWeight: "900",
    color: "royalblue",
    textAlign: "left",
    paddingLeft: 10,
    marginVertical: 5,
  },
  details: {
    fontSize: 21,
    fontWeight: "500",
    color: "gray",
    textAlign: "left",
    paddingLeft: 10,
    marginVertical: 25,
  },
  answers: {
    fontSize: 16,
    color: "black",
    fontWeight: "600",
    textAlign: "left",
    paddingLeft: 10,
    marginVertical: 10,
  },
  appointmentHeader: {
    fontSize: 22,
    marginTop: 10,
    color: materialTheme.COLORS.INFO,
    paddingLeft: 10,
    alignSelf: "flex-start",
  },

  appointmentHeader1: {
    fontSize: 34,
    marginVertical: 10,
    textAlign: "center",
    color: materialTheme.COLORS.PRIMARY,
  },
  button: {
    height: 48,
    justifyContent: "center",
    marginTop: 10,
    marginVertical: 10,
    backgroundColor: materialTheme.COLORS.BUTTON_COLOR,
  },
  cancelButton: {
    height: 48,
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 30,
    marginVertical: 10,
    backgroundColor: materialTheme.COLORS.ERROR,
  },
  disabledButton: {
    height: 48,
    justifyContent: "center",
    marginTop: 10,
    marginVertical: 10,
    backgroundColor: materialTheme.COLORS.BLOCK,
  },
  image: {
    height: 25,
    width: 25,
  },
  notes: {
    fontSize: 17,
    marginVertical: 10,
    textAlign: "center",
    paddingHorizontal: 10,
    fontWeight: "500",
    color: materialTheme.COLORS.CAPTION,
  },
});
export default AppointmentDetail;
