import React, { useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { Card, TextInput as Input, Text, Menu } from "react-native-paper";
import { materialTheme } from "../constants/";
import { TextInputMask } from "react-native-masked-text";
import data from "../../data.json";

const { height, width } = Dimensions.get("window");

const PersonalInfoForm2 = (props) => {
  const { styles, errors, touched } = props;
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  return (
    <View style={{ flex: 1 }}>
      <Text style={styles.label}>Date of Birth:*</Text>
      <TextInputMask
        type="datetime"
        options={{
          format: "MM/DD/YYYY",
        }}
        placeholder="MM/DD/YYYY"
        name="dateOfBirth"
        autoCapitalize="none"
        bgColor="transparent"
        placeholderTextColor={"gray"}
        style={[
          styles.maskinput,
          props.active.dateOfBirth ? styles.maskInputActive : null,
        ]}
        theme={{
          colors: {
            text: materialTheme.COLORS.INPUT_TEXT,
            primary: materialTheme.COLORS.INPUT_TEXT,
          },
        }}
        underlineColor="transparent"
        onBlur={() => props.onBlur("dateOfBirth")}
        onFocus={() => props.toggleActive("dateOfBirth")}
        onChangeText={(text) => props.handleChange(text, "dateOfBirth")}
        value={props.personalInfo.dateOfBirth}
      />
      {errors.dateOfBirth && touched.dateOfBirth && (
        <Text style={styles.errorText}>{errors.dateOfBirth}</Text>
      )}
      <Text style={styles.label}>Gender at Birth:*</Text>
      <Menu
        visible={open2}
        onDismiss={() => setOpen2(false)}
        anchor={
          <TouchableOpacity
            style={[
              styles.input2,
              {
                backgroundColor: "transparent",
                justifyContent: "center",
                paddingLeft: 10,
              },
            ]}
            onPress={() => setOpen2(true)}
          >
            <Text
              style={{
                marginHorizontal: 5,
                color: materialTheme.COLORS.INPUT_TEXT,
              }}
            >
              {props.personalInfo.gender
                ? props.personalInfo.gender
                : "Select Gender"}
            </Text>
            {/* <Image
                source={require("../../../assets/down-arrow.png")}
                style={{ width: 15, height: 15, marginHorizontal: 5 }}
              /> */}
          </TouchableOpacity>
        }
      >
        {[
          { name: "Male", value: "M" },
          { name: "Female", value: "F" },
        ].map((item) => {
          return (
            <Menu.Item
              key={item.name}
              onPress={() => {
                setOpen2(false);
                props.handleChange(item.value, "gender");
              }}
              title={item.name}
            />
          );
        })}
      </Menu>
      {errors.gender && touched.gender && (
        <Text style={styles.errorText}>{errors.gender}</Text>
      )}
      <Text style={styles.label}>Home Phone:*</Text>
      <Input
        type="default"
        options={{
          mask: "(999) 999-9999",
        }}
        placeholder="Home Phone"
        name="homePhone"
        autoCapitalize="none"
        bgColor="transparent"
        placeholderTextColor={"gray"}
        style={[
          styles.maskinput,
          props.active.homePhone ? styles.maskInputActive : null,
        ]}
        onFocus={() => props.toggleActive("homePhone")}
        onBlur={() => props.onBlur("homePhone")}
        onChangeText={(text) => props.handleChange(text, "homePhone")}
        value={props.personalInfo.homePhone}
      />
      {errors.homePhone && touched.homePhone && (
        <Text style={styles.errorText}>{errors.homePhone}</Text>
      )}
      <Text style={styles.label}>Mobile Phone:*</Text>
      <Input
        type="default"
        options={{
          mask: "(999) 999-9999",
        }}
        placeholder="Mobile Phone"
        name="mobilePhone"
        autoCapitalize="none"
        bgColor="transparent"
        placeholderTextColor={"gray"}
        style={[
          styles.maskinput,
          props.active.mobilePhone ? styles.maskInputActive : null,
        ]}
        onFocus={() => props.toggleActive("mobilePhone")}
        onBlur={() => props.onBlur("mobilePhone")}
        onChangeText={(text) => props.handleChange(text, "mobilePhone")}
        value={props.personalInfo.mobilePhone}
      />
      {errors.mobilePhone && touched.mobilePhone && (
        <Text style={styles.errorText}>{errors.mobilePhone}</Text>
      )}
      <Text style={styles.label}>E-mail:</Text>
      <Input
        placeholder="Email"
        color={materialTheme.COLORS.INPUT_TEXT}
        type="email-address"
        name="email"
        autoCapitalize="none"
        bgColor="transparent"
        placeholderTextColor={"gray"}
        style={[styles.input, props.active.email ? styles.inputActive : null]}
        theme={{
          colors: {
            text: materialTheme.COLORS.INPUT_TEXT,
            primary: materialTheme.COLORS.INPUT_TEXT,
          },
        }}
        underlineColor="transparent"
        onFocus={() => props.toggleActive("email")}
        onBlur={() => props.onBlur("email")}
        onChangeText={(text) => props.handleChange(text, "email")}
        value={props.personalInfo.email}
      />
      {errors.email && props.personalInfo.email && (
        <Text style={styles.errorText}>{errors.email}</Text>
      )}
      <Text style={styles.label}>Contact Preference:</Text>
      <Menu
        visible={open1}
        onDismiss={() => setOpen1(false)}
        contentStyle={{ marginTop: 60 }}
        anchor={
          <TouchableOpacity
            style={[
              styles.input2,
              {
                backgroundColor: "transparent",
                justifyContent: "center",
                paddingLeft: 10,
              },
            ]}
            onPress={() => setOpen1(true)}
          >
            <Text
              style={{
                marginHorizontal: 5,
                color: materialTheme.COLORS.INPUT_TEXT,
              }}
            >
              {props.personalInfo.conatactPreference
                ? props.personalInfo.conatactPreference
                : "Select Cantact Preference"}
            </Text>
          </TouchableOpacity>
        }
      >
        {data.contact_preferences.map((item) => {
          return (
            <Menu.Item
              key={item.value}
              onPress={() => {
                setOpen1(false);
                props.handleChange(item.value, "conatactPreference");
              }}
              title={item.name}
            />
          );
        })}
      </Menu>
      <Text
        style={{
          marginHorizontal: 5,
          marginVertical: 15,
          color: materialTheme.COLORS.ERROR,
        }}
      >
        ** Please Update the changes before moving to next screen. **
      </Text>
    </View>
  );
};
export default PersonalInfoForm2;
