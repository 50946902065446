import React, { useState } from "react";
import { Dimensions } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { Icon, Header } from "../ui/";
import { Images, materialTheme } from "../constants/";
import CustomDrawerContent from "./Menu";

// screens

import OnboardingScreen from "../screens/OnboardingScreen";
import DashboardScreen from "../screens/DashboardScreen";
import SignInScreen from "../screens/SignInScreen";
import SignUpScreen from "../screens/SignUpScreen";
import ChangePasswordScreen from "../screens/ChangePasswordScreen";
import PersonalInfoScreen from "../screens/PersonalInfoScreen";
import AppointmentsScreen from "../screens/AppointmentsScreen";
import AppointmentDetailScreen from "../screens/AppointmentDetailScreen";
import SignBillingInfoScreen from "../screens/SignBillingInfoScreen";
import SignPrivacyNoticeScreen from "../screens/SignPrivacyNoticeScreen";
import UploadCardScreen from "../screens/UploadCardScreen";

const { width } = Dimensions.get("screen");
const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const profile = {
  avatar: Images.Profile,
  name: "Rachel Brown",
  type: "Seller",
  plan: "Pro",
  rating: 4.8,
};

function DashboardStack(props) {
  let user = props.user;
  return (
    <Stack.Navigator initialRouteName="Dashboard">
      <Stack.Screen
        name="Dashboard"
        options={{
          header: ({ navigation, scene }) => (
            <Header
              search
              options
              title="Dashboard"
              navigation={navigation}
              scene={scene}
            />
          ),
        }}
      >
        {(props) => <DashboardScreen {...props} user={user} />}
      </Stack.Screen>
      <Stack.Screen
        name="MyInfo"
        options={{
          header: ({ navigation, scene }) => (
            <Header
              search
              back
              title="Personal Info"
              navigation={navigation}
              scene={scene}
            />
          ),
        }}
      >
        {(props) => <PersonalInfoScreen {...props} user={user} />}
      </Stack.Screen>
    </Stack.Navigator>
  );
}

function AuthStack(props) {
  return (
    <Drawer.Navigator headerMode="none" initialRouteName="Sign In">
      <Drawer.Screen
        name="Home"
        component={AppStack}
        options={{
          headerShown: false,
        }}
      />
      <Drawer.Screen
        name="Sign In"
        component={SignInScreen}
        options={{
          headerShown: false,
        }}
      />
      <Drawer.Screen
        name="Sign Up"
        component={SignUpScreen}
        options={{
          headerShown: false,
        }}
      />
      <Drawer.Screen
        name="ChangePassword"
        component={ChangePasswordScreen}
        options={{
          headerShown: false,
        }}
      />
      <Drawer.Screen
        name="AppStack"
        options={{
          headerShown: false,
        }}
      >
        {(props) => <AppStack {...props} />}
      </Drawer.Screen>
    </Drawer.Navigator>
  );
}

function AppStack(props) {
  let user = props.user;
  return (
    <Drawer.Navigator
      screenOptions={{
        headerShown: false,
      }}
      drawerContent={(props) => (
        <CustomDrawerContent {...props} profile={profile} user={user} />
      )}
      drawerStyle={{
        backgroundColor: "white",
        width: width >= 768 ? width * 0.4 : width * 0.8,
      }}
      initialRouteName="Dashboard"
    >
      <Drawer.Screen name="Dashboard">
        {(props) => <DashboardStack {...props} user={user} />}
      </Drawer.Screen>
      <Drawer.Screen name="Appointments">
        {(props) => (
          <AppointmentStack {...props} user={user} name="Appointments" />
        )}
      </Drawer.Screen>
      <Drawer.Screen name="Auth" component={AuthStack} />
    </Drawer.Navigator>
  );
}

function AppointmentStack(props) {
  const [data, setData] = useState("");
  let user = props.user;
  let name = props.name;
  // const callbackValues = (arg) => {
  //   setData(arg)
  // }
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Appointments"
        options={{
          header: ({ navigation, scene }) => (
            <Header
              search
              options
              title="Appointments"
              navigation={navigation}
              scene={scene}
            />
          ),
        }}
      >
        {(props) => <AppointmentsScreen {...props} user={user} />}
      </Stack.Screen>
      <Stack.Screen
        name="AppointmentDetail"
        options={{
          header: ({ navigation, scene }) => (
            <Header back title="Appointment Details" navigation={navigation} />
          ),
        }}
      >
        {(props) => (
          <AppointmentDetailScreen {...props} user={user} data={data} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name="SignPrivacyNotice"
        options={{
          header: ({ navigation, scene }) => (
            <Header back title="Sign Privacy Notice" navigation={navigation} />
          ),
        }}
      >
        {(props) => (
          <SignPrivacyNoticeScreen {...props} user={user} data={data} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name="SignBillingInfo"
        options={{
          header: ({ navigation, scene }) => (
            <Header back title="Sign Billing Info" navigation={navigation} />
          ),
        }}
      >
        {(props) => (
          <SignBillingInfoScreen {...props} user={user} data={data} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name="UploadCard"
        options={{
          header: ({ navigation, scene }) => (
            <Header back title="Upload Card" navigation={navigation} />
          ),
        }}
      >
        {(props) => <UploadCardScreen {...props} user={user} data={data} />}
      </Stack.Screen>
    </Stack.Navigator>
  );
}

function OnboardingStack(props) {
  let user = props.user;
  let isLoggedIn = props.loggedInStatus;
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="Onboarding"
        option={{
          headerTransparent: true,
        }}
      >
        {(props) => (
          <OnboardingScreen {...props} user={user} isLoggedIn={isLoggedIn} />
        )}
      </Stack.Screen>
      <Stack.Screen name="App">
        {props.loggedInStatus
          ? (props) => <AppStack {...props} user={user} />
          : () => <AuthStack />}
      </Stack.Screen>
    </Stack.Navigator>
  );
}
export default OnboardingStack;
