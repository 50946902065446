import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Dimensions,
  ScrollView,
  Image,
  Alert,
  Modal,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  View,
} from "react-native";
import { Text, Portal, Dialog, Paragraph } from "react-native-paper";
import { Button } from "../ui/";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import { Auth, Storage } from "aws-amplify";
import Amplify from "@aws-amplify/core";
import config from "../../aws-exports";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { materialTheme } from "../constants";
import { fetchSignedUrl, fetchUser, upgradeUser } from "../api/users";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { allFacilities } from "../api/providers";
import { updateAthenaApi, uploadAthenaDocument } from "../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { listTaskCategorys } from "../graphql/queries";

Storage.configure({
  AWSS3: {
    bucket: config.aws_user_files_s3_bucket, //Your bucket name;
    region: config.aws_user_files_s3_bucket_region, //Specify the region your bucket was created in;
    identityPoolId: config.aws_cognito_identity_pool_id,
  },
});
Auth.configure(config);
Amplify.configure(config);
const { width } = Dimensions.get("screen");

const UploadCard = (props) => {
  // console.log(facility, "dfsdfssfds")
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [side, setSide] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uri1Loading, setUri1Loading] = useState(false);
  const [uri2Loading, setUri2Loading] = useState(false);
  const [uri3Loading, setUri3Loading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [license, setLicense] = useState("");
  const [licenseImage, setLicenseImage] = useState("");

  const [upload, setUpload] = useState({
    fornt: false,
    back: false,
  });

  const [patientId] = useState(props.route.params.patientId);
  const [departmentId] = useState(props.route.params.departmentId);
  const [practiceId] = useState(props.route.params.practiceId);
  const [document] = useState(props.route.params.document);

  const choosePhoto = (e) => {
    setSide(e);
    setModalVisible(true);
  };

  const onUploadLicense = async () => {
    try {
      setLoading(true);
      let token = await AsyncStorage.getItem("Token");
      let url = `https://api.platform.athenahealth.com/v1/${practiceId}/patients/${patientId}/driverslicense`;
      let image_data = license.split(",")[1];
      await API.graphql(
        graphqlOperation(uploadAthenaDocument, {
          url,
          base64: image_data,
          departmentId: parseInt(departmentId),
          documentSubClass: "DRIVINGLICENSE",
          token: token,
        })
      )
        .then((res) => {
          console.log("---------------->", res);
          if (res.data.uploadAthenaDocument.status === 200) {
            alert("License Uploaded successfully");
            props.navigation.goBack();
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          alert(err.message);
        });
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  const pickImage = async (type) => {
    if (type == "camera") {
      let permissionResult = await ImagePicker.requestCameraPermissionsAsync();
      console.log(permissionResult);
      if (permissionResult.granted) {
        onChooseCamera();
      } else {
        Alert.alert("", "You must give permissions to access your camera", [
          { text: "OK", onPress: () => setModalVisible(!modalVisible) },
        ]);
      }
    } else {
      let permissionResult =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (permissionResult.granted) {
        onChooseLibrary();
      } else {
        Alert.alert("", "You must give permissions to access your library.", [
          { text: "OK", onPress: () => setModalVisible(!modalVisible) },
        ]);
      }
    }
  };
  const onChooseCamera = async () => {
    Platform.OS != "web" && setModalLoading(true);
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      aspect: [4, 3],
      quality: 1,
    });
    console.log("webbbbbbbbb check...", result);
    setModalLoading(false);
    setModalVisible(!modalVisible);
    if (!result.cancelled) {
      console.log("haillddddddddddfdf...", result);
      if (side == "front") {
        setUrl1(result.uri);
        setImage1(result);
      } else if (side == "back") {
        setUrl2(result.uri);
        setImage2(result);
      } else {
        setLicense(result.uri);
        setLicenseImage(result);
      }
    }
    console.log(result);
  };
  const onChooseLibrary = async () => {
    Platform.OS != "web" && setModalLoading(true);
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      aspect: [4, 3],
      quality: 1,
    });
    setModalLoading(false);
    setModalVisible(!modalVisible);
    if (!result.cancelled) {
      if (side == "front") {
        setUrl1(result.uri);
        setImage1(result);
      } else if (side == "back") {
        setUrl2(result.uri);
        setImage2(result);
      } else {
        setLicense(result.uri);
        setLicenseImage(result);
      }
    }
    console.log(result);
  };

  const addDocument = async (img, i) => {
    try {
      setLoading(true);
      console.log(img);
      const base = img.split(";")[0];
      const extension = base.split("/").pop();
      let attachment_type = extension.toUpperCase();
      let image_data = img.split(",")[1];
      console.log("attachment_type", attachment_type);
      let token = await AsyncStorage.getItem("Token");
      let url = `https://api.platform.athenahealth.com/v1/${practiceId}/patients/${patientId}/documents/admin`;
      await API.graphql(
        graphqlOperation(uploadAthenaDocument, {
          url,
          base64: image_data,
          documentType: attachment_type,
          departmentId: parseInt(departmentId),
          documentSubClass: "INSURANCEView",
          token: token,
        })
      )
        .then((res) => {
          console.log("---------------->", res);
          if (i === 0 && res.status === 200) {
            setUpload({
              ...upload,
              front: true,
            });
          } else {
            setUpload({
              ...upload,
              back: true,
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          alert(err);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    console.log("uploading insurance View");
    for (let i = 0; i < 2; i++) {
      let image = i === 0 ? url1 : url2;
      await addDocument(image, i);
    }
    alert("Insurace Uploaded successfully");
    props.navigation.goBack();
  };

  return loading ? (
    <View style={{ flex: 1 }}>
      <ActivityIndicator
        size="large"
        animating={loading}
        color={materialTheme.COLORS.BRAND}
        style={{ margin: 10, flex: 1, alignSelf: "center" }}
      />
    </View>
  ) : document === "INSURANCE" ? (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        behavior="padding"
        enabled
        contentContainerStyle={{ flexGrow: 1 }}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.content}>
          {url1 == "" ? (
            <View style={styles.content}>
              <Image
                style={styles.image}
                source={require("../../assets/images/card1.png")}
              />
              <Text style={styles.text1}>Upload Insurance View</Text>
              <Text style={styles.text2}>Please take photo of your View</Text>
            </View>
          ) : null}
          <View style={styles.content}>
            {url1 != "" ? (
              <TouchableOpacity
                style={{
                  backgroundColor: "lightgray",
                  borderRadius: 5,
                  marginVertical: 10,
                }}
                onPress={() =>
                  Platform.OS == "web"
                    ? choosePhoto("front")
                    : !uri1Loading && choosePhoto("front")
                }
              >
                <Image
                  style={styles.ViewImage}
                  source={{ uri: url1 }}
                  onLoadStart={() => setUri1Loading(true)}
                  onLoadEnd={() => setUri1Loading(false)}
                  resizeMode="stretch"
                />
                {Platform.OS != "web" && uri1Loading && (
                  <ActivityIndicator
                    color={materialTheme.COLORS.PRIMARY}
                    size={"small"}
                    style={{
                      position: "absolute",
                      bottom: 100,
                      alignSelf: "center",
                    }}
                  />
                )}
              </TouchableOpacity>
            ) : null}
            {url2 != "" ? (
              <TouchableOpacity
                style={{
                  backgroundColor: "lightgray",
                  borderRadius: 5,
                  marginTop: 10,
                }}
                onPress={() =>
                  Platform.OS == "web"
                    ? choosePhoto("back")
                    : !uri2Loading && choosePhoto("back")
                }
                // onPress={() => !uri2Loading && choosePhoto("back")}
              >
                <Image
                  style={styles.ViewImage}
                  source={{ uri: url2 }}
                  onLoadStart={() => setUri2Loading(true)}
                  onLoadEnd={() => setUri2Loading(false)}
                  resizeMode="stretch"
                />
                {Platform.OS != "web" && uri2Loading && (
                  <ActivityIndicator
                    color={materialTheme.COLORS.PRIMARY}
                    size={"small"}
                    style={{
                      position: "absolute",
                      bottom: 100,
                      alignSelf: "center",
                    }}
                  />
                )}
              </TouchableOpacity>
            ) : null}
            {url1 == "" ? (
              <Button
                style={styles.button}
                onPress={() => choosePhoto("front")}
              >
                <Text style={styles.buttonText}>Upload Front</Text>
              </Button>
            ) : null}
            {url2 == "" ? (
              <Button style={styles.button} onPress={() => choosePhoto("back")}>
                <Text style={styles.buttonText}>Upload Back</Text>
              </Button>
            ) : null}
            {url1 != "" && url2 != "" ? (
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <Text style={{ marginVertical: 10 }}>
                  * If you want to update View please click on View image
                </Text>
                <Button
                  style={[
                    styles.button,
                    { opacity: !image1 || !image2 ? 0.4 : 1 },
                  ]}
                  disabled={!image1 || !image2}
                  onPress={onSubmit}
                >
                  <Text style={styles.buttonText}>Upload</Text>
                </Button>
              </View>
            ) : null}
          </View>
          <Portal>
            <Dialog
              visible={modalVisible}
              onDismiss={() => setModalVisible(false)}
              style={{
                width: width <= 768 ? width * 0.9 : width * 0.5,
                alignSelf: "center",
                elevation: 0,
              }}
            >
              {modalLoading ? (
                <View
                  style={{
                    elevation: 0,
                    height: 200,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      alignSelf: "center",
                      fontWeight: "500",
                    }}
                  >
                    Opening....
                  </Text>
                  <ActivityIndicator style={{ padding: 10 }} />
                </View>
              ) : (
                <Dialog.Content
                  style={{
                    elevation: 0,
                  }}
                >
                  <View style={styles.modalView}>
                    <Text style={styles.modalText}>
                      Choose photo select method.....
                    </Text>
                    <Button
                      style={{
                        ...styles.button,
                        backgroundColor: "#2196F3",
                      }}
                      onPress={() => {
                        // setModalVisible(!modalVisible)
                        pickImage("gallery");
                      }}
                    >
                      <Text style={styles.textStyle}>Gallery</Text>
                    </Button>
                    {Platform.OS == "web" ? null : (
                      <Button
                        style={{
                          ...styles.button,
                          backgroundColor: "#2196F3",
                        }}
                        onPress={() => {
                          // setModalVisible(!modalVisible)
                          pickImage("camera");
                        }}
                      >
                        <Text style={styles.textStyle}>Camera</Text>
                      </Button>
                    )}
                    <Button
                      style={{
                        ...styles.button,
                        backgroundColor: "#2196F3",
                      }}
                      onPress={() => {
                        setModalVisible(!modalVisible);
                      }}
                    >
                      <Text style={styles.textStyle}>Cancel</Text>
                    </Button>
                  </View>
                </Dialog.Content>
              )}
            </Dialog>
          </Portal>
        </View>
      </KeyboardAwareScrollView>
    </View>
  ) : (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        behavior="padding"
        enabled
        contentContainerStyle={{ flexGrow: 1 }}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.content}>
          {license == "" ? (
            <View style={styles.content}>
              <Image
                style={styles.image}
                source={require("../../assets/images/card1.png")}
              />
              <Text style={styles.text1}>Upload Driving License</Text>
              <Text style={styles.text2}>Please take photo of your View</Text>
            </View>
          ) : null}
          <View style={styles.content}>
            {license != "" ? (
              <TouchableOpacity
                style={{
                  backgroundColor: "lightgray",
                  borderRadius: 5,
                  marginVertical: 10,
                }}
                onPress={() =>
                  Platform.OS == "web"
                    ? choosePhoto("license")
                    : !uri13oading && choosePhoto("license")
                }
              >
                <Image
                  style={styles.ViewImage}
                  source={{ uri: license }}
                  onLoadStart={() => setUri3Loading(true)}
                  onLoadEnd={() => setUri3Loading(false)}
                  resizeMode="stretch"
                />
                {Platform.OS != "web" && uri3Loading && (
                  <ActivityIndicator
                    color={materialTheme.COLORS.PRIMARY}
                    size={"small"}
                    style={{
                      position: "absolute",
                      bottom: 100,
                      alignSelf: "center",
                    }}
                  />
                )}
              </TouchableOpacity>
            ) : null}
            {license == "" ? (
              <Button
                style={styles.button}
                onPress={() => choosePhoto("license")}
              >
                <Text style={styles.buttonText}>Upload License</Text>
              </Button>
            ) : null}

            {license != "" ? (
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <Text style={{ marginVertical: 10 }}>
                  * If you want to update please click on View image
                </Text>
                <Button
                  style={[styles.button, { opacity: !licenseImage ? 0.4 : 1 }]}
                  disabled={!licenseImage}
                  onPress={onUploadLicense}
                >
                  <Text style={styles.buttonText}>Upload</Text>
                </Button>
              </View>
            ) : null}
          </View>
          <Portal>
            <Dialog
              visible={modalVisible}
              onDismiss={() => setModalVisible(false)}
              style={{
                width: width <= 768 ? width * 0.9 : width * 0.5,
                alignSelf: "center",
                elevation: 0,
              }}
            >
              {modalLoading ? (
                <View
                  style={{
                    elevation: 0,
                    height: 200,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      alignSelf: "center",
                      fontWeight: "500",
                    }}
                  >
                    Opening....
                  </Text>
                  <ActivityIndicator style={{ padding: 10 }} />
                </View>
              ) : (
                <Dialog.Content
                  style={{
                    elevation: 0,
                  }}
                >
                  <View style={styles.modalView}>
                    <Text style={styles.modalText}>
                      Choose photo select method.....
                    </Text>
                    <Button
                      style={{
                        ...styles.button,
                        backgroundColor: "#2196F3",
                        marginTop: 10,
                      }}
                      onPress={() => {
                        // setModalVisible(!modalVisible)
                        pickImage("gallery");
                      }}
                    >
                      <Text style={styles.textStyle}>Gallery</Text>
                    </Button>
                    {Platform.OS == "web" ? null : (
                      <Button
                        style={{
                          ...styles.button,
                          backgroundColor: "#2196F3",
                          marginVertical: 10,
                        }}
                        onPress={() => {
                          // setModalVisible(!modalVisible)
                          pickImage("camera");
                        }}
                      >
                        <Text style={styles.textStyle}>Camera</Text>
                      </Button>
                    )}
                    <Button
                      style={{
                        ...styles.button,
                        backgroundColor: "#2196F3",
                        marginTop: 20,
                      }}
                      onPress={() => {
                        setModalVisible(!modalVisible);
                      }}
                    >
                      <Text style={styles.textStyle}>Cancel</Text>
                    </Button>
                  </View>
                </Dialog.Content>
              )}
            </Dialog>
          </Portal>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#63C8F2",
    flex: 1,
    justifyContent: "center",
    alignSelf: "center",
    elevation: 0,
    width: width,
  },
  content: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 0,
  },
  home: {
    width: width,
  },
  button: {
    marginVertical: 5,
    backgroundColor: materialTheme.COLORS.BUTTON_COLOR,
  },
  buttonText: {
    padding: 15,
    color: "white",
  },
  image: {
    height: width >= 768 ? width * 0.35 : 200,
    width: width >= 768 ? width * 0.4 : width - 50,
    // width: width - 50,
    tintColor: "white",
  },
  ViewImage: {
    // height: 200,
    height: width >= 768 ? 280 : 200,
    width: width >= 768 ? width * 0.4 : width - 50,
    // width: width - 50,
    aspectRatio: 3 / 2,
    margin: 5,
    borderRadius: 5,
  },
  text1: {
    fontSize: 34,
    fontWeight: "bold",
    marginTop: 10,
    color: "white",
  },
  text2: {
    fontSize: 22,
    marginTop: 10,
    color: "white",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "red",
  },
  modalView: {
    margin: 20,
    borderRadius: 5,
    padding: 35,
    elevation: 0,
    alignItems: "center",
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 0,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 3.84,
    // elevation: 5,
  },
  openButton: {
    padding: 10,
    elevation: 2,
    marginVertical: 5,
  },
});
export default UploadCard;
