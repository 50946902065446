import React, { useEffect, useState } from "react";
import {
  TouchableWithoutFeedback,
  ScrollView,
  StyleSheet,
  Dimensions,
  Image,
  View,
} from "react-native";
import { Card, Text } from "react-native-paper";

import { useSafeArea } from "react-native-safe-area-context";

import { Drawer as DrawerCustomItem } from "../ui/";
import { materialTheme } from "../constants/";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { fetchUser } from "../api/users";

const { width } = Dimensions.get("screen");

function CustomDrawerContent({
  drawerPosition,
  navigation,
  profile,
  focused,
  state,
  user,
  ...props
}) {
  const insets = useSafeArea();
  const screens = ["Dashboard", "Appointments", "My Info"];
  const [userData, setUserData] = useState("");
  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    let userId = await AsyncStorage.getItem("userId");
    fetchUser(userId)
      .then((res) => setUserData(res.data.getUser))
      .catch((err) => console.log(err));
  };
  return (
    <View
      style={styles.container}
      forceInset={{ flex: 1, top: "always", horizontal: "never" }}
    >
      <View style={[styles.header, { flex: 0.23 }]}>
        <TouchableWithoutFeedback>
          <View style={styles.profile}>
            <Image
              source={require("../../assets/images/image-placeholder.png")}
              style={styles.avatar}
            />
            <Text color={"#5b5b5b"} size={18} style={{ fontWeight: "600" }}>
              {userData ? userData.email.toUpperCase() : ""}
            </Text>
          </View>
        </TouchableWithoutFeedback>
      </View>
      <View style={{ flex: 1, paddingHorizontal: 2 }}>
        <ScrollView
          contentContainerStyle={[
            {
              paddingTop: insets.top * 0.4,
              paddingLeft: drawerPosition === "left" ? insets.left : 0,
              paddingRight: drawerPosition === "right" ? insets.right : 0,
            },
          ]}
          showsVerticalScrollIndicator={false}
        >
          {screens.map((item, index) => {
            return (
              <DrawerCustomItem
                title={item}
                key={index}
                navigation={navigation}
                focused={state.index === index ? true : false}
              />
            );
          })}
          <DrawerCustomItem
            title="Logout"
            navigation={navigation}
            focused={state.index === 8 ? true : false}
          />
        </ScrollView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    backgroundColor: materialTheme.COLORS.BRAND,
    paddingTop: 40,
    justifyContent: "center",
  },
  footer: {
    paddingHorizontal: 28,
    justifyContent: "flex-end",
  },
  profile: {
    marginBottom: 5,
    marginTop: 20,
  },
  avatar: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginBottom: 10,
  },
  pro: {
    backgroundColor: materialTheme.COLORS.LABEL,
    paddingHorizontal: 6,
    marginRight: 8,
    borderRadius: 4,
    height: 19,
    width: 38,
  },
  seller: {
    marginRight: 16,
  },
});

export default CustomDrawerContent;
