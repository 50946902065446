import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Image,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Alert,
  View,
} from "react-native";
import { Card, Text } from "react-native-paper";
import { materialTheme } from "../constants/";
import { fetchTaskByFPAIds } from "../api/tasks";
import { fetchUser } from "../api/users";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button } from "../ui/";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useIsFocused } from "@react-navigation/native";

import { API, graphqlOperation } from "aws-amplify";
import config from "../../aws-exports";
import { getAthenaApi } from "../graphql/queries";
import { updateAthenaApi } from "../graphql/mutations";

const { height, width } = Dimensions.get("window");

const MyTasks = (props) => {
  // let facilityId = props.facilityId
  // let appointmentId = props.appointmentId
  // let facility = props.facility
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState(true);
  const [pId, setPId] = useState("");
  const [fId, setFId] = useState("");

  useEffect(() => {
    fetchData();
  }, [isFocused]);

  useEffect(() => {
    async function getData() {
      let emrdata = await AsyncStorage.getItem("EmrApptIds");
      let parsedData = JSON.parse(emrdata);
      let patientId = parsedData.pId;
      let practiceId = parsedData.fId;
      setPId(patientId);
      setFId(practiceId);
    }
    getData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    getPatientInfo();
    getPrivacyNotice();
  };

  const [appointment, setAppointment] = useState(props.appointment);
  const [departmentId, setDepartmentId] = useState(props.departmentId);
  const [privacyNotice, setPrivacyNotice] = useState(false);
  const [billing, setBilling] = useState(false);
  const [personalInfo, setPersonalInfo] = useState({
    id: null,
    firstName: "",
    lastName: "",
    socialSecurityNumber: "",
    address: "",
    city: "",
    zipCode: "",
    state: "",
    country: "",
    dateOfBirth: "",
    gender: "",
    homePhone: "",
    mobilePhone: "",
    email: "",
    conatactPreference: "",
    emergencyCantactName: "",
    emergencyCantactPhone: "",
    marritalStatus: "",
    language: "",
    ethnicity: "",
    race: "",
    consenToCall: false,
    driversLicenseNumber: "",
    driverLienseState: "",
    driverLicenseExpirationDate: "",
    ssn: "",
  });

  const getPatientInfo = async () => {
    setLoading(true);
    let token = await AsyncStorage.getItem("Token");
    let emrdata = await AsyncStorage.getItem("EmrApptIds");
    let parsedData = JSON.parse(emrdata);
    let practiceId = parsedData.fId;
    let patientId = parsedData.pId;
    let url = `https://api.platform.athenahealth.com/v1/${practiceId}/patients/${patientId}`;
    await API.graphql(
      graphqlOperation(getAthenaApi, { apiUrl: url, token: token })
    )
      .then((res) => {
        let response = res.data.getAthenaApi.response;
        let json = JSON.parse(response);
        let obj = json[0];
        if (obj) {
          setPersonalInfo({
            firstName: obj.firstname ? obj.firstname : undefined,
            lastName: obj.lastname ? obj.lastname : undefined,
            socialSecurityNumber: obj.ssn ? obj.ssn : undefined,
            address: obj.address1 ? obj.address1 : undefined,
            city: obj.city ? obj.city : undefined,
            zipCode: obj.zip ? obj.zip : undefined,
            state: obj.state ? obj.state : undefined,
            country: obj.countrycode ? obj.countrycode : undefined,
            dateOfBirth: obj.dob ? obj.dob : undefined,
            gender: obj.sex ? obj.sex : undefined,
            homePhone: obj.homephone ? obj.homephone : undefined,
            mobilePhone: obj.mobilephone ? obj.mobilephone : undefined,
            email: obj.email ? obj.email : undefined,
            conatactPreference: obj.contactpreference
              ? obj.contactpreference
              : undefined,
            emergencyCantactName: obj.contactname ? obj.contactname : undefined,
            emergencyCantactPhone: obj.contactmobilephone
              ? obj.contactmobilephone
              : undefined,
            marritalStatus: obj.maritalstatusname
              ? obj.maritalstatusname
              : undefined,
            language: obj.language6392code ? obj.language6392code : undefined,
            ethnicity: obj.ethnicity ? obj.ethnicity : undefined,
            race: obj.race ? obj.race : undefined,
            consenToCall: obj.consenttocall ? obj.consenttocall : undefined,
            driversLicenseNumber: obj.driverslicensenumber
              ? obj.driverslicensenumber
              : undefined,
            driverLienseState: obj.driverslicensestateid
              ? obj.driverslicensestateid
              : undefined,
            driverLicenseExpirationDate: obj.driverslicenseexpirationdate
              ? obj.driverslicenseexpirationdate
              : undefined,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error while fetching patient Information", err);
        setLoading(false);
      });
  };

  const getPrivacyNotice = async () => {
    setLoading(true);
    let token = await AsyncStorage.getItem("Token");
    let emrdata = await AsyncStorage.getItem("EmrApptIds");
    let parsedData = JSON.parse(emrdata);
    let practiceId = parsedData.fId;
    let patientId = parsedData.pId;
    let url = `https://api.platform.athenahealth.com/v1/${practiceId}/patients/${patientId}/privacyinformationverified?departmentid=${departmentId}`;
    await API.graphql(
      graphqlOperation(getAthenaApi, { apiUrl: url, token: token })
    )
      .then((res) => {
        let response = res.data.getAthenaApi.response;
        let json = JSON.parse(response);
        if (json) {
          let privacy = json.privacynotice.isprivacynoticeonfile;
          let bill = json?.patientsignature.ispatientsignatureonfile;
          setPrivacyNotice(privacy);
          setBilling(bill);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("error while fetching Privacy Notice", err);
        setLoading(false);
      });
  };

  const Onvalidate = () => {
    const errors = {};
    if (personalInfo.firstName) {
      if (personalInfo.firstName === "" || !personalInfo.firstName) {
        errors.firstName = "firstName";
      }
    } else {
      errors.name = "firstName";
    }
    if (personalInfo.lastName) {
      if (personalInfo.lastName === "" || !personalInfo.lastName) {
        errors.lastName = "lastName";
      }
    } else {
      errors.name = "first Name";
    }
    if (personalInfo.socialSecurityNumber) {
      if (
        personalInfo.socialSecurityNumber === "" ||
        !personalInfo.socialSecurityNumber
      ) {
        errors.socialSecurityNumber = "socialSecurityNumber";
      }
    } else {
      errors.socialSecurityNumber = "socialSecurityNumber";
    }
    if (personalInfo.address) {
      if (personalInfo.address === "" || !personalInfo.address) {
        errors.address = "address";
      }
    } else {
      errors.address = "address";
    }
    if (personalInfo.city) {
      if (personalInfo.city === "" || !personalInfo.city) {
        errors.city = "city";
      }
    } else {
      errors.city = "city";
    }
    if (personalInfo.zipCode) {
      if (personalInfo.zipCode === "" || !personalInfo.zipCode) {
        errors.zipCode = "zipCode";
      }
    } else {
      errors.zipCode = "zipCode";
    }
    if (personalInfo.state) {
      if (personalInfo.state === "" || !personalInfo.state) {
        errors.state = "state";
      }
    } else {
      errors.state = "state";
    }
    if (personalInfo.country) {
      if (personalInfo.country === "" || !personalInfo.country) {
        errors.country = "country";
      }
    } else {
      errors.country = "country";
    }
    if (personalInfo.dateOfBirth) {
      if (personalInfo.dateOfBirth === "" || !personalInfo.dateOfBirth) {
        errors.dateOfBirth = "dateOfBirth";
      }
    } else {
      errors.dateOfBirth = "dateOfBirth";
    }
    if (personalInfo.gender) {
      if (personalInfo.gender === "" || !personalInfo.gender) {
        errors.gender = "gender";
      }
    } else {
      errors.gender = "gender";
    }
    if (personalInfo.homePhone) {
      if (personalInfo.homePhone === "" || !personalInfo.homePhone) {
        errors.homePhone = "homePhone";
      }
    } else {
      errors.homePhone = "homePhone";
    }
    if (personalInfo.mobilePhone) {
      if (personalInfo.mobilePhone === "" || !personalInfo.mobilePhone) {
        errors.mobilePhone = "mobilePhone";
      }
    } else {
      errors.mobilePhone = "mobilePhone";
    }
    if (personalInfo.email) {
      if (personalInfo.email === "" || !personalInfo.email) {
        errors.email = "email";
      }
    } else {
      errors.email = "email";
    }
    if (personalInfo.conatactPreference) {
      if (
        personalInfo.conatactPreference === "" ||
        !personalInfo.conatactPreference
      ) {
        errors.conatactPreference = "conatactPreference";
      }
    } else {
      errors.conatactPreference = "conatactPreference";
    }
    if (personalInfo.emergencyCantactName) {
      if (
        personalInfo.emergencyCantactName === "" ||
        !personalInfo.emergencyCantactName
      ) {
        errors.emergencyCantactName = "emergencyCantactName";
      }
    } else {
      errors.emergencyCantactName = "emergencyCantactName";
    }
    if (personalInfo.emergencyCantactPhone) {
      if (
        personalInfo.emergencyCantactPhone === "" ||
        !personalInfo.emergencyCantactPhone
      ) {
        errors.emergencyCantactPhone = "emergencyCantactPhone";
      }
    } else {
      errors.emergencyCantactPhone = "emergencyCantactPhone";
    }
    if (personalInfo.marritalStatus) {
      if (personalInfo.marritalStatus === "" || !personalInfo.marritalStatus) {
        errors.marritalStatus = "marritalStatus";
      }
    } else {
      errors.marritalStatus = "marritalStatus";
    }
    if (personalInfo.language) {
      if (personalInfo.language === "" || !personalInfo.language) {
        errors.language = "language";
      }
    } else {
      errors.language = "language";
    }
    // if (personalInfo.ethnicity) {
    //   if (personalInfo.ethnicity === '' || !personalInfo.ethnicity) {
    //     errors.ethnicity = 'ethnicity';
    //   }
    // } else {
    //   errors.ethnicity = 'ethnicity';
    // }
    if (personalInfo.race) {
      if (personalInfo.race === "" || !personalInfo.race) {
        errors.race = "race";
      }
    } else {
      errors.email = "race";
    }
    if (personalInfo.consenToCall) {
      if (personalInfo.consenToCall === "" || !personalInfo.consenToCall) {
        errors.consenToCall = "consenToCall";
      }
    } else {
      errors.consenToCall = "consenToCall";
    }
    if (personalInfo.driversLicenseNumber) {
      if (
        personalInfo.driversLicenseNumber === "" ||
        !personalInfo.driversLicenseNumber
      ) {
        errors.driversLicenseNumber = "driversLicenseNumber";
      }
    } else {
      errors.driversLicenseNumber = "driversLicenseNumber";
    }
    if (personalInfo.driverLienseState) {
      if (
        personalInfo.driverLienseState === "" ||
        !personalInfo.driverLienseState
      ) {
        errors.driverLienseState = "driverLienseState";
      }
    } else {
      errors.driverLienseState = "driverLienseState";
    }
    if (personalInfo.driverLicenseExpirationDate) {
      if (
        personalInfo.driverLicenseExpirationDate === "" ||
        !personalInfo.driverLicenseExpirationDate
      ) {
        errors.race = "driverLicenseExpirationDate";
      }
    } else {
      errors.driverLicenseExpirationDate = "driverLicenseExpirationDate";
    }
    return {
      errors,
      isInfoCompleted: Object.keys(errors).length === 0,
    };
  };

  const { isInfoCompleted } = Onvalidate();

  const verifyStatus = isInfoCompleted && privacyNotice && billing;

  useEffect(() => {
    if (verifyStatus) {
      props.onTaskCompleted();
    }
  }, [verifyStatus]);

  return loading ? (
    <View style={{ flex: 1 }}>
      <ActivityIndicator
        size="large"
        animating={loading}
        color={materialTheme.COLORS.BRAND}
        style={{ margin: 10, flex: 1, alignSelf: "center" }}
      />
    </View>
  ) : (
    <KeyboardAwareScrollView
      behavior="padding"
      style={{ flex: 1 }}
      contentContainerStyle={{ alignItems: "center" }}
      enabled
    >
      <View style={styles.task}>
        <TouchableOpacity
          style={styles.taskBox}
          TouchableOpacity={0.5}
          onPress={() => props.navigation.navigate("MyInfo")}
        >
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.taskText}>{"Enter Personal Info"}</Text>
            {isInfoCompleted ? (
              <Image
                source={require("../../assets/images/checked2.png")}
                style={{ height: 18, width: 18, alignSelf: "center" }}
              />
            ) : null}
          </View>
          <Image
            source={require("../../assets/icons/forward.png")}
            style={styles.image}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.task}>
        <TouchableOpacity
          style={styles.taskBox}
          TouchableOpacity={0.5}
          onPress={() =>
            props.navigation.navigate("SignPrivacyNotice", {
              departmentId: departmentId,
              patientId: pId,
              practiceId: fId,
              privacyNotice: privacyNotice,
              patientName: personalInfo.firstName + " " + personalInfo.lastName,
            })
          }
        >
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.taskText}>{"Sign Privacy Notice"}</Text>
            {privacyNotice ? (
              <Image
                source={require("../../assets/images/checked2.png")}
                style={{ height: 18, width: 18, alignSelf: "center" }}
              />
            ) : null}
          </View>
          <Image
            source={require("../../assets/icons/forward.png")}
            style={styles.image}
          />
        </TouchableOpacity>
      </View>

      <View style={styles.task}>
        <TouchableOpacity
          style={styles.taskBox}
          TouchableOpacity={0.5}
          onPress={() =>
            props.navigation.navigate("SignBillingInfo", {
              departmentId: departmentId,
              patientId: pId,
              practiceId: fId,
              billingNotice: billing,
              patientName: personalInfo.firstName + " " + personalInfo.lastName,
            })
          }
        >
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.taskText}>{"Sign Billing Information"}</Text>
            {billing ? (
              <Image
                source={require("../../assets/images/checked2.png")}
                style={{ height: 18, width: 18, alignSelf: "center" }}
              />
            ) : null}
          </View>
          <Image
            source={require("../../assets/icons/forward.png")}
            style={styles.image}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.task}>
        <TouchableOpacity
          style={styles.taskBox}
          TouchableOpacity={0.5}
          onPress={() =>
            props.navigation.navigate("UploadCard", {
              departmentId: departmentId,
              patientId: pId,
              practiceId: fId,
              document: "INSURANCE",
            })
          }
        >
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.taskText}>{"Upload Insurace Card"}</Text>
          </View>
          <Image
            source={require("../../assets/icons/forward.png")}
            style={styles.image}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.task}>
        <TouchableOpacity
          style={styles.taskBox}
          TouchableOpacity={0.5}
          onPress={() =>
            props.navigation.navigate("UploadCard", {
              departmentId: departmentId,
              patientId: pId,
              practiceId: fId,
              document: "DRIVING",
            })
          }
        >
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.taskText}>{"Upload Driving license"}</Text>
          </View>
          <Image
            source={require("../../assets/icons/forward.png")}
            style={styles.image}
          />
        </TouchableOpacity>
      </View>
    </KeyboardAwareScrollView>
  );
};
const styles = StyleSheet.create({
  task: {
    height: 80,
    width: width - 20,
    padding: 10,
    borderBottomWidth: 1,
    borderColor: materialTheme.COLORS.MUTED,
    backgroundColor: materialTheme.COLORS.BLOCK,
  },
  taskBox: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  taskText: {
    textAlign: "center",
    fontSize: 18,
    marginRight: 10,
    color: materialTheme.COLORS.PRIMARY,
  },
  image: {
    height: 30,
    width: 30,
    tintColor: materialTheme.COLORS.PRIMARY,
  },
  button: {
    height: 48,
    width: 350,
    justifyContent: "center",
    marginTop: 10,
    marginVertical: 10,
    backgroundColor: materialTheme.COLORS.BUTTON_COLOR,
  },
  cancelButton: {
    height: 48,
    width: 350,
    justifyContent: "center",
    marginTop: 10,
    marginVertical: 10,
    backgroundColor: materialTheme.COLORS.ERROR,
  },
  disabledButton: {
    height: 48,
    width: 350,
    justifyContent: "center",
    marginTop: 10,
    marginVertical: 10,
    backgroundColor: materialTheme.COLORS.BLOCK,
  },
  buttonText: {
    padding: 15,
    color: "white",
  },
});
export default MyTasks;
