import React from "react";
import { Card, TextInput as Input, Text } from "react-native-paper";
import { materialTheme } from "../constants/";
import { TextInputMask } from "react-native-masked-text";
import { View } from "react-native";

const PersonalInfoForm3 = (props) => {
  const { styles, errors } = props;
  return (
    <View style={{ flex: 1 }}>
      <Text style={styles.label}>Name:</Text>
      <Input
        placeholder="Name"
        color={materialTheme.COLORS.INPUT_TEXT}
        type="default"
        name="emergencyCantactName"
        autoCapitalize="none"
        bgColor="transparent"
        placeholderTextColor={"gray"}
        style={[
          styles.input,
          props.active.emergencyCantactName ? styles.inputActive : null,
        ]}
        theme={{
          colors: {
            text: materialTheme.COLORS.INPUT_TEXT,
            primary: materialTheme.COLORS.INPUT_TEXT,
          },
        }}
        underlineColor="transparent"
        onFocus={() => props.toggleActive("emergencyCantactName")}
        onChangeText={(text) =>
          props.handleChange(text, "emergencyCantactName")
        }
        value={props.personalInfo.emergencyCantactName}
      />
      {errors.emergencyCantactName && (
        <Text style={styles.errorText}>{errors.emergencyCantactName}</Text>
      )}
      <Text style={styles.label}>Mobile Phone:*</Text>
      <Input
        type="default"
        options={{
          mask: "(999) 999-9999",
        }}
        placeholder="Mobile Phone"
        name="emergencyCantactPhone"
        autoCapitalize="none"
        bgColor="transparent"
        placeholderTextColor={"gray"}
        style={[
          styles.maskinput,
          props.active.emergencyCantactPhone ? styles.maskInputActive : null,
        ]}
        onFocus={() => props.toggleActive("emergencyCantactPhone")}
        onBlur={() => props.onBlur("emergencyCantactPhone")}
        onChangeText={(text) =>
          props.handleChange(text, "emergencyCantactPhone")
        }
        value={props.personalInfo.emergencyCantactPhone}
      />
      {errors.emergencyCantactPhone && (
        <Text style={styles.errorText}>{errors.emergencyCantactPhone}</Text>
      )}

      <Text
        style={{
          marginHorizontal: 5,
          marginVertical: 15,
          color: materialTheme.COLORS.ERROR,
        }}
      >
        ** Please Update the changes before moving to next screen. **
      </Text>
    </View>
  );
};
export default PersonalInfoForm3;
